const products = [
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE MARS WOLF CHELSEA BOOT - TAN SUEDE",
      href: "products/the-mars-wolf-chelsea-boot-tan-suede",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_37683ec15b594dd79467d3a367f77090_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_965bb3f9d0a44d7a8a9536f653adadef_grande.jpg",
      href: "/products/the-mars-wolf-chelsea-boot-tan-suede",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_37683ec15b594dd79467d3a367f77090_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_965bb3f9d0a44d7a8a9536f653adadef_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_a1433182195646d596e8266eff76db82_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_bcc5b859d43b4e3e85bcee38b1702b5d_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_d87cc6f2df6140ceb5a56b741026664f_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_a8ce5fafa0444fbd84f2c4717114484f_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_58a59ea85a55495a9bae4aee7dfd3058_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_87a4afcb012e4e409bf8c2ab082414c4_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_7718bdb1e89c4348bb9b37276e9b939d_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_a389c3e7af6b474296712ae2b946d3ed_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "TMWCSBTS-1",
    price: { saleprice: "1,800,000₫", realprice: "2,400,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "TAN", color: "rgb(225, 179, 130)" },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE MARS WOLF CHELSEA BOOT - BLACK SUEDE",
      href: "products/the-mars-wolf-chelsea-boot-black-suede",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_5854fa0ea7fa4b709f6a1f154c1ece8b_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_7293d39bc55d4a4aa7958d4643ed3ea9_grande.jpg",
      href: "/products/the-mars-wolf-chelsea-boot-black-suede",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_5854fa0ea7fa4b709f6a1f154c1ece8b_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_7293d39bc55d4a4aa7958d4643ed3ea9_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_dfb2b36547744d1fa57600d2707323f7_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_0baa5164d5b648ce93e78b0386b80a4f_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_0922a0e01a6d4b4e888d3ff0bf690660_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_38808b5416504f0a80be408603af2298_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_bfdf87d1e4da420bbf8f8bb7afea0725_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TMWCSBBS-1",
    price: { saleprice: "1,800,000₫", realprice: "2,400,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE MARS WOLF CHELSEA BOOT SPECIAL EDITION - BLACK",
      href: "products/the-mars-wolf-chelsea-boot-special-edition-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_b4e517f8116943afbb687158c880a419_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_12566a1ceef44398a539d427762873fe_grande.jpg",
      href: "/products/the-mars-wolf-chelsea-boot-special-edition-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 867,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_b4e517f8116943afbb687158c880a419_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_12566a1ceef44398a539d427762873fe_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_ce52721e5f9c4cd3aef21926f52984de_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_0d5bff0c2d1f4fc182ac75ad4e11e762_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_22395eba4fcb471aa63027577b75284e_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_6c183547adae47a2958ef76e0fa08a30_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_878267d34345452d8383d029f04e5bab_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2__2__1f747a7ec74e4196b914567f26a99dfe_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_76dfef8a8c634527998715ff38782c23_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_c154483cba9442c6a41fb37be61f0ded_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "TMWCSBSEB-1",
    price: { saleprice: "2,600,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE MARS WOLF CHELSEA BOOT - BLACK",
      href: "products/the-mars-wolf-chelsea-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_071068beb0904e37b19b11b9408d102c_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_1708360bf5594073874dc576ea574d17_grande.jpg",
      href: "/products/the-mars-wolf-chelsea-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_071068beb0904e37b19b11b9408d102c_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_1708360bf5594073874dc576ea574d17_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_93756c7e8c3242cc9a0452810ae05916_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_f856cdb966414f27a581ba04c0b7ad50_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_a8e7728f785e428987adb35da7ae52e6_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_abacfc87caf440e4bc3596b67bc8fe2b_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/8_d9d6f2f8923d405a9d71cccb65e5e7d8_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TMWCSBB-1",
    price: { saleprice: "1,800,000₫", realprice: "2,400,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE WOLF MARBE CHUNKY CHELSEA BOOT - BEIGE",
      href: "products/the-wolf-marbe-chunky-chelsea-boot-beige",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_03ab52b2e9834602ba0fd52bc01d7fb1_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_d027b7ab58e7426f82f28d417ffae6c2_grande.jpg",
      href: "/products/the-wolf-marbe-chunky-chelsea-boot-beige",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_03ab52b2e9834602ba0fd52bc01d7fb1_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_d027b7ab58e7426f82f28d417ffae6c2_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_ceb4f92c2f0241a3a704ed81aa39ea4a_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_f9de6958b8184207ad2302136c0c614d_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_bcab4e24b6fb48178594cc34fd9cd3bf_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_f208e43086134e5cbae6d5dad1ea1fd9_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/14_06300587b6764fc6a7b0b7444a8a37ec_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TWMCBB-0",
    price: { saleprice: "800,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-60%",
    colors: { title: "BEIGE", color: "rgb(240, 188, 135)" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE WOLF MINIMAL CHELSEA BOOT - TAN",
      href: "products/the-wolf-minimal-chelsea-boot-tan",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_9a257564e4a14390904d7a67d6a9d202_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_64cff3e2d8b4444fb27a285c339380c0_grande.jpg",
      href: "/products/the-wolf-minimal-chelsea-boot-tan",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 425,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_9a257564e4a14390904d7a67d6a9d202_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_64cff3e2d8b4444fb27a285c339380c0_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_7d5bf27cae99481b95dbb34411488d19_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_6faa0db54c6447d9beef20290594cfce_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_80b469adde6447cc92e27c068404341d_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_314a97d619e94e158ba789c79c0c5270_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/10_0e79afe6dfa84eef8a882123249ce692_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_a509f7033b77444ba3cddc9cb7ba5b7a_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_837c0756da224fd788a7b4f935897a05_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "NMWCBT-1",
    price: { saleprice: "1,275,000₫", realprice: "1,700,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "TAN", color: "rgb(225, 179, 130)" },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE WOLF MINIMAL CHELSEA BOOT - BLACK",
      href: "products/the-wolf-minimal-chelsea-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_f81095732c6a4183839445ad1617a9bb_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_d36091b9973b4a96978963988a8689d3_grande.jpg",
      href: "/products/the-wolf-minimal-chelsea-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 425,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_f81095732c6a4183839445ad1617a9bb_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_d36091b9973b4a96978963988a8689d3_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_ff1bdc7177ad4d8ea4d7ca8e2cc3907f_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_4e128be52e4d41f799fa7239f4642397_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_c79456273e7b416ca01e1c6a15d5ad3b_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_e33d79215d3b4a79ae0c58550f00940c_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/9_ddb208969fd249a98186ef4a8e5b445a_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_d96dbdc454b74835b25b17b24db3befc_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_6877ff87ef2c4f95a096c111f7b4946d_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "NMWCBB-1",
    price: { saleprice: "1,275,000₫", realprice: "1,700,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE WOLF CHUNKY CHELSEA BOOT - BLACK",
      href: "products/the-chunky-chelsea-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_a239e00342fa459091bcfc334520dee2_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_57c6ac21f62e41848c8882efaaadaa31_grande.jpg",
      href: "/products/the-chunky-chelsea-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 500,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_a239e00342fa459091bcfc334520dee2_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_57c6ac21f62e41848c8882efaaadaa31_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_e0c2cf218a2743a6b687aecdab269d00_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_b5339ea30a9e4c83b488df98b3327140_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_dafc3adeed9942b09463d18928cc2d43_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ad19b42c14304f63a04e88baea19287d_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/13_170cae69643e48e7910165f87e60987a_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_776f86568f8549b2980fb0e52f0c5b03_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_0f2575dd211e4ac29a6f4b0aa2948239_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TCKCSBB-0",
    price: { saleprice: "1,500,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE CLASSIC CHELSEA BOOT - BLACK",
      href: "products/the-classic-chelsea-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_394ea4191335408f9cc10c9fb8b9c631_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_2d9b3be48cc143fdb83dd255635f5700_grande.jpg",
      href: "/products/the-classic-chelsea-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 420,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_394ea4191335408f9cc10c9fb8b9c631_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_2d9b3be48cc143fdb83dd255635f5700_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_141e083eca0e4b48b081086e65bce3c7_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_fd48270cb6a944cf873d5f8a6088a276_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_aca619075c6d46cea14713f82bccfe40_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_b938ec370bd94b13b82ee3076395682d_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/12_218dd765360f4e6882927aae98e6ee63_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "62CMDL-2",
    price: { saleprice: "1,260,000₫", realprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-30%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE WILD WALK CHELSEA BOOT – BLACK",
      href: "products/the-wild-walk-chelsea-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_186b513dc0ce4f37a163200188cb48db_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/3_1fb162436e6349aaa0de18719383644a_grande.jpg",
      href: "/products/the-wild-walk-chelsea-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 479,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_186b513dc0ce4f37a163200188cb48db_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_1fb162436e6349aaa0de18719383644a_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_fbc8f8ebcf13461eb2dff4b9841993d6_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_0a09538f461c4be5864ec3f41d23ca7a_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_274faba53a92485e8ac0790ea495fc2d_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_aafc0e4ee7804320aad3d10bfa00fbb3_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_e2b800768a534623b27b82078dca450b_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_6ecabe709d8e4a77b636d241dfffc08b_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_5f68b56dd45d453999a3eb6e3cf9b1ed_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "65WCMB-1",
    price: { saleprice: "1,435,000₫", realprice: "2,050,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-30%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE WILD WALK CHELSEA BOOT – TAN",
      href: "products/the-wild-walk-chelsea-boot-tan",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_cfc15ec05df74c478fc01bd94d46ccbd_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_a906ae9f720f4de38f7a1347e500ecab_grande.jpg",
      href: "/products/the-wild-walk-chelsea-boot-tan",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_cfc15ec05df74c478fc01bd94d46ccbd_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_a906ae9f720f4de38f7a1347e500ecab_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_d23934f4601843d39cec05f82584c053_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_ad7c4c4fa55c479a897d38a20b226778_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_44f04098847c4b979ef72680dd851980_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_214c9d3242b9445abbb584ace8caf045_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_f8986e3921994c109229007c5fecbada_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "65WCMBT-1",
    price: { saleprice: "820,000₫", realprice: "2,050,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-60%",
    colors: { title: "TAN", color: "rgb(225, 179, 130)" },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE WILD WALK CHELSEA BOOT – BLACK SUEDE",
      href: "products/the-wild-walk-chelsea-boot-black-suede",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_25c98ffbd2114e85b12b7778e0f5f8df_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_be5b77e1076949f788146bf83f3378f0_grande.jpg",
      href: "/products/the-wild-walk-chelsea-boot-black-suede",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 410,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_25c98ffbd2114e85b12b7778e0f5f8df_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_be5b77e1076949f788146bf83f3378f0_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_06d43d8f9fa145409fa45090df028ff3_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_1e29f03370ad41ad998fdbeb41b8c840_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_7b2dbd74c7fe4041a7af99b5b0c168ec_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_79b6f91fbb314f619e968e3d25827f1d_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_861121b94b634845a2134c9d9feabde3_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "65WCBS-1",
    price: { saleprice: "1,230,000₫", realprice: "2,050,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE WILD WALK CHELSEA BOOT – BLACK LIZARD",
      href: "products/the-wild-walk-chelsea-boot-black-lizard",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_aacacf20a8c646dc8d6f105b08a71aac_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_9f2c12659c4e48dcb966dcb091338c8d_grande.jpg",
      href: "/products/the-wild-walk-chelsea-boot-black-lizard",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 440,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_aacacf20a8c646dc8d6f105b08a71aac_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_9f2c12659c4e48dcb966dcb091338c8d_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_c63ef1c18a534d73a90f04114fb35cc0_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_b08170ebfc7543059dff32d009210b63_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_1c74d5ec76c949bd8e25c32fc953008d_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_c8e99a84d1d5475f9e66dbca844692aa_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_19f0c330f0db4f69ad39a22b60c69cbd_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "65WCMBL-1",
    price: { saleprice: "1,320,000₫", realprice: "2,200,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nam",
    soldout: null,
    title: {
      content: "THE CLASSIC CHELSEA BOOT - ĐEN DA LỘN",
      href: "products/the-classic-chelsea-boot-den-da-lon",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_cdf240ac00be4a75a08675e963e95543_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_a6d2139d3bef4cbba2f08ff847203d76_grande.jpg",
      href: "/products/the-classic-chelsea-boot-den-da-lon",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 360,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_cdf240ac00be4a75a08675e963e95543_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_a6d2139d3bef4cbba2f08ff847203d76_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_9266a1cc0f8a433781d98de3c1435d6a_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_15c3ad05cef846bea0a05f8c03eb8b87_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_60999fa237524b5c88c537340c579e57_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_495aad8ec0bc4788a61b8f35be599f58_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/11_828ca7f0fa874e0880c21924fe571184_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "62CMDL-4",
    price: { saleprice: "1,080,000₫", realprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "derby-nam",
    soldout: null,
    title: {
      content: "THE MARS WOLF DERBY - BLACK",
      href: "products/the-mars-wolf-derby-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_25ccd02310964c94bb28967445424f9c_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_1e24b3e81ef7405a95192a41122473a2_grande.jpg",
      href: "/products/the-mars-wolf-derby-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 613,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_25ccd02310964c94bb28967445424f9c_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_1e24b3e81ef7405a95192a41122473a2_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_f3e31d736c7e405d9c59b5d74cc00c75_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_0695d0c5948a4fd19835d792d6af9473_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_7423bf38f80c424da13ca0dc689f5ed4_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ca569ae49ae54adc911f36e10f0d50e3_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_4b5bfc0b304c4a48b39ec611ee67d917_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_373dcdc9ffe34271bd5c56085925a0e6_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_af1ec0e1d8de4a1692b8e747a32dde64_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TMWDBB-1",
    price: { saleprice: "1,837,500₫", realprice: "2,450,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "derby-nam",
    soldout: null,
    title: {
      content: "THEWOLF MODERN EVA BROGUE DERBY - BLACK",
      href: "products/thewolf-modern-eva-brogue-derby-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_4a636ff44b134445931cede6a0e77454_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_dbb7ec69554240519def746d999e5c41_grande.jpg",
      href: "/products/thewolf-modern-eva-brogue-derby-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 400,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_4a636ff44b134445931cede6a0e77454_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_dbb7ec69554240519def746d999e5c41_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_aa8cba0b5c154f8784d72c7e5e6a3748_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_1e33e4ca54fb4027b03d5010263523ac_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_1d9a118771a842249fe9cc78140de356_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_f35645eeffaf48d9b969bd45323d351c_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/9_1fe109fcb9984d3390cae1a9df102290_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_da4f751d4deb4f9eae8e42c023b62f80_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_f726b980be244c1e8a991d5dd5ee70e4_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_8aefe4b0d9544e9e909565716a0e2490_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "4466EVA2-1",
    price: { saleprice: "1,200,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "derby-nam",
    soldout: null,
    title: {
      content: "THEWOLF MODERN EVA DERBY - BLACK",
      href: "products/thewolf-modern-eva-derby-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_f4b2a79e52b346d484ff0062703ccf2d_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_1ef4abfe07984deb866331160771980e_grande.jpg",
      href: "/products/thewolf-modern-eva-derby-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 420,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_f4b2a79e52b346d484ff0062703ccf2d_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_1ef4abfe07984deb866331160771980e_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_6b079edeae6342e98f55bfd39dd80bfd_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_94dc38a06eb94935a878faa2c979af9f_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_9b3a42ac62f64ef287c64d37bcb42487_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_99e795104cd84b0b8e960ce59017d4a6_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/8_9570b5d2768b4cc1aacb1d85d8209ffe_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_7e1dafa9a61d4ea8a7877f70b670b7fc_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_8cca3df31f9140749379ed01d34de1cb_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_84ccf0cd8f954a37890f35962a3ab396_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "4466EVA-0",
    price: { saleprice: "1,260,000₫", realprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-30%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "derby-nam",
    soldout: null,
    title: {
      content: "THE WOLF CHUNKY DERBY - BLACK WHITE",
      href: "products/the-wolf-chunky-derby-black-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_b29d64a19ebf4e4c84cb922271622fff_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_aadd700ec01a4a82818bb8a67b761b85_grande.jpg",
      href: "/products/the-wolf-chunky-derby-black-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 500,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_b29d64a19ebf4e4c84cb922271622fff_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_aadd700ec01a4a82818bb8a67b761b85_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_63e97a09b36e42b19b339ff259552728_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_902eae2bbf60412e8318a418a26bb479_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_19d9b0f5791149f8ae32d61f1ffb7bc7_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_7e687fe935b64560b31053e74cd02528_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_b22227acd271466dba547f20e0b1f38a_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TCKDBBW-0",
    price: { saleprice: "1,500,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "derby-nam",
    soldout: null,
    title: {
      content: "THE WOLF MARBE CHUNKY DERBY - BEIGE",
      href: "products/the-wolf-marbe-chunky-derby-beige",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_8cb96985fe774590bae82942d57fdc61_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_2809f59eb04d4e27920b2946086914bc_grande.jpg",
      href: "/products/the-wolf-marbe-chunky-derby-beige",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_8cb96985fe774590bae82942d57fdc61_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_2809f59eb04d4e27920b2946086914bc_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_e15de39ca1724d0ab9c7ebd06f5546e8_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_e992c245a0464769866fbba0381b4f9e_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_b11114737ecb4b5595773415aa060d81_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ded59293ada84ec9b9bba87f77c73f08_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_9bca7d2d89764c5c895daf3bcc5d291f_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TMCKDBB-0",
    price: { saleprice: "800,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-60%",
    colors: { title: "BEIGE", color: "rgb(240, 188, 135)" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "derby-nam",
    soldout: null,
    title: {
      content: "THE WOLF CHUNKY DERBY 4 EYELET - BLACK",
      href: "products/thewolf-chunky-derby-4-eyelet-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_c9d7972611cf413a97723c3dfedcd8e1_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_1d4a9a58223941b8bd6431975defd6cb_grande.jpg",
      href: "/products/thewolf-chunky-derby-4-eyelet-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 400,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_c9d7972611cf413a97723c3dfedcd8e1_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_1d4a9a58223941b8bd6431975defd6cb_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_dfe160fd1ccd4dbc972054435c6dafe1_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_a39702ad25e84621b22ec255e86e0f0f_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_ef9c5befce654877b13a0affc3669b6a_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_3a97054b50944575977d8bce3ca35852_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_733be951c858449a8e9e33cd1b561c24_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TWCKD4EB-0",
    price: { saleprice: "1,200,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "derby-nam",
    soldout: null,
    title: {
      content: "THE WOLF CHUNKY DERBY - BLACK",
      href: "products/the-chunky-derby-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_a7dd2d10a2064e7bb731635ad0bf5f6a_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_1ed3b58763e546999f76662dbddcadf5_grande.jpg",
      href: "/products/the-chunky-derby-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 500,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_a7dd2d10a2064e7bb731635ad0bf5f6a_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_1ed3b58763e546999f76662dbddcadf5_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_db5aa4a78d8b44928eb7b94cca9cb684_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_00eac159d1d543c1b414cf6de2966047_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_bbfed53cb63243219c0dee257531d539_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_63568d1199b74e29ac23185697ce8dca_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_a9fa072fe56f4f44aaea4e2dbf7e0716_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TCKDBB-0",
    price: { saleprice: "1,500,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "derby-nam",
    soldout: null,
    title: {
      content: "THE MODERN DERBY – BLACK",
      href: "products/the-modern-derby-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_b5ce37b41b04450aba7983832cdf6e2d_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_1dc70d0676af40fcb932c7fe1c2f891f_grande.jpg",
      href: "/products/the-modern-derby-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 400,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_b5ce37b41b04450aba7983832cdf6e2d_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_1dc70d0676af40fcb932c7fe1c2f891f_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_19a890b7d6774780817c168675a98e87_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_5d70201defb7456bbadc8327b7810cd2_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_405a2c39d8184c6fb451150411fdd0c8_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_350c2619b1dc4a9789de0ed4ddea2429_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_99f8383fb6374855a27922960dbc1508_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_0fe291c0399642189c3cfd36f30ac6fb_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_570670de16cb436db3468661dacd4725_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "4466MDB-0",
    price: { saleprice: "1,200,000₫", realprice: "1,600,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "derby-nam",
    soldout: null,
    title: {
      content: "THE MODERN DERBY – BLACK GRAIN",
      href: "products/the-modern-derby-black-grain",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_91abd81a804e4b01963ca311cfe937d3_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_dde1593cc8c9496fb9dc0463069945ae_grande.jpg",
      href: "/products/the-modern-derby-black-grain",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 420,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_91abd81a804e4b01963ca311cfe937d3_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_dde1593cc8c9496fb9dc0463069945ae_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_29b935c169d6450395fca48c7af40eba_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_7889297b98d848619f482a263271f44c_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_d618074d4cac404a871541e6947dc24e_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ffea260e8c3c47e3a6a5189c03bb4213_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_7725f805d3be4ddaa1eb27aaa611fada_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "4466MDBG-0",
    price: { saleprice: "1,260,000₫", realprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-30%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "derby-nam",
    soldout: null,
    title: {
      content: "THE MODERN BROGUE DERBY – BLACK GRAIN AND WHITE",
      href: "products/the-modern-brogue-derby-black-grain-and-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_bfc5e56f667642c697c12836ae1a5a28_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_3445729a05e040798ab262922ce46fe4_grande.jpg",
      href: "/products/the-modern-brogue-derby-black-grain-and-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 420,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_bfc5e56f667642c697c12836ae1a5a28_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_3445729a05e040798ab262922ce46fe4_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_dbaf2c5bdba94668a4e7074f0c93c761_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_e350884d89004485b8b9c6b30722adcf_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_2fe8b91c74934f2aa004c0e6f13cfa1b_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_3ca2c2449dc3459294bc0a5b027b9003_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_276d988dec264fbbaae5c4faa49ddc37_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "66MDDH-1",
    price: { saleprice: "1,260,000₫", realprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-30%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "derby-nam",
    soldout: null,
    title: {
      content: "THE 4CM HEEL DERBY – BLACK LIZARD",
      href: "products/the-4cm-heel-derby-black-lizard",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_108ee41a17f547d282cd261bb5fe1654_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_a18281b3f02941399083afc88db88dd6_grande.jpg",
      href: "/products/the-4cm-heel-derby-black-lizard",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 340,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_108ee41a17f547d282cd261bb5fe1654_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_a18281b3f02941399083afc88db88dd6_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_20fe6dd6afb4422085fd369c1cf83df7_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_40eb93117ac649e08d5feaae8d06386e_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_dd6c68cdb35d421db0dbccdfa4b8311e_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ec23b39d4c1347e183ec8f06d2b40171_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/10_838a00b9db9b4376aaee3d368389c8d8_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "65HDBL-0",
    price: { saleprice: "1,020,000₫", realprice: "1,700,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THE SEAN WOLF CHUNKY LOAFER - BLACK OFF WHITE",
      href: "products/the-sean-wolf-chunky-loafer-black-off-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1.2_a6c975d1922945588b5baa534f41cf1d_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2.2_0ed45c816edd4b3baa11e8a86276cdba_grande.jpg",
      href: "/products/the-sean-wolf-chunky-loafer-black-off-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 667,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1.2_a6c975d1922945588b5baa534f41cf1d_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2.2_0ed45c816edd4b3baa11e8a86276cdba_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_407d957928384a2aa8860df2c91f5a96_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_7f2cb4e28b224fd6a2d5886aaccc1bd1_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_5cb1e6b563e94f628defb23e4a8d92fa_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/ban_sao_cua_7_4c3b5a2f12d54fd59ea4e73a029933d2_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ae02373b309249d2b83b094cc473b47c_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWCKLFBW-1",
    price: { saleprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THE SEAN WOLF CHUNKY LOAFER - BLACK",
      href: "products/the-sean-wolf-chunky-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1.2_5e17d7249a4c484b80def698ad58f653_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2.2_d306dca79a6542bfa1b4a95a957e44ca_grande.jpg",
      href: "/products/the-sean-wolf-chunky-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 667,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1.2_5e17d7249a4c484b80def698ad58f653_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2.2_d306dca79a6542bfa1b4a95a957e44ca_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_906939852bd24067b20e724a72cdc3da_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_a600c62632484448954f6fc6e2f7d925_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_963e9a5f9aa94b91ba71349bbf8f087a_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/ban_sao_cua_7_1f3bf80d7bf94c248cad5201a5cef1a5_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_3acd7eabc3f849708be8b91f25dc436a_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWCKLFB-1",
    price: { saleprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 RU">43 RU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THE SEAN WOLF MODERN LOAFER - BLACK OFF WHITE",
      href: "products/the-sean-wolf-modern-loafer-black-off-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_bf7eb1ac2c3d4ac9a5536afbcb8d38f3_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_2f6eb0e813cc48f297902c3b4facd716_grande.jpg",
      href: "/products/the-sean-wolf-modern-loafer-black-off-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_bf7eb1ac2c3d4ac9a5536afbcb8d38f3_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_2f6eb0e813cc48f297902c3b4facd716_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_124d26da37e5455395f5dc1973353416_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_c53209eb2f374de9bdec2423d71f0f32_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_fdccb6536aa74cb69b5e4503f07c3cdb_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_4c176d8cd21d47d99610c079ef9ffb4c_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ed14433bad5142f692ce4d95361d19b6_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWMDLFBW-1",
    price: { saleprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THE SEAN WOLF MODERN LOAFER - BLACK",
      href: "products/the-sean-wolf-modern-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_3c8dae73de314ca6bb817dd6501729eb_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_27cc1c6a50844ee393eb4f04b6a9ad77_grande.jpg",
      href: "/products/the-sean-wolf-modern-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_3c8dae73de314ca6bb817dd6501729eb_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_27cc1c6a50844ee393eb4f04b6a9ad77_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_3d12cdbebadd4eeabedc07c9bde97898_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_9edecc7b005440fa893e0b5aab2a56d0_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_a55d12e26c5d4183832f85a7d8f07114_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_20e6d095848945d58e2b13dc13cb677e_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_3d777fa3d3ed4fbc8a261d2b9ad304c6_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWMDLFB-1",
    price: { saleprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THEWOLF PENNY LOAFER - BLACK WHITE",
      href: "products/thewolf-penny-loafer-black-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_8c3ff4ffe9634a559785e735081fa2ca_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_13d3d1b555a240eeb71e49d3989bbf1d_grande.jpg",
      href: "/products/thewolf-penny-loafer-black-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 425,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_8c3ff4ffe9634a559785e735081fa2ca_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_13d3d1b555a240eeb71e49d3989bbf1d_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_3801cd4037d545949fbbdc160ea62c95_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_cdf7e977a04e4d2289e4aa5d778c0b78_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_8470b254299e4226b66bbcd9ce56dba4_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_6fc2566276ef47e196449605b1d618c4_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/the_wolf_penny_loafer_-_black_white_cad76b6f152e4171ae1fe5e8366dde14_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_96c49246ea0f4727acdb1e1c5d925cd2_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_f614358b9468419488a1a3b626d6b743_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TWPNLFW-0",
    price: { saleprice: "1,275,000₫", realprice: "1,700,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THEWOLF PENNY LOAFER - BLACK",
      href: "products/thewolf-penny-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_a0c2aeb405934168b8cef7297b42b6a9_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_20e6ac5bd56a4a83b6bc7825b5869b4e_grande.jpg",
      href: "/products/thewolf-penny-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 425,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_a0c2aeb405934168b8cef7297b42b6a9_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_20e6ac5bd56a4a83b6bc7825b5869b4e_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_789fa27143184afaa44c0ce087b790e8_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_9515b8e79ebb4055b7bb3a579b9280f5_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_e7ec37c8df684441a172c83d738eed60_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_86cba5b7d1684b01b669fbcee4931e0c_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_penny_loafer_-_black_efb647faea3c4cf980935d92154ff269_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_23c3b44a86d1450fa68106a54352d18c_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_2bc573bb2deb40f997ecb210fcce6a9a_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TWPNLF-0",
    price: { saleprice: "1,275,000₫", realprice: "1,700,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THEWOLF MODERN EVA LOAFER - BLACK",
      href: "products/thewolf-modern-eva-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_38ff612842c34a14bb01b2f63d2e977c_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_102a1baa190743f5af4fe1f284a11075_grande.jpg",
      href: "/products/thewolf-modern-eva-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 380,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_38ff612842c34a14bb01b2f63d2e977c_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_102a1baa190743f5af4fe1f284a11075_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_e85db83a9bde4bb9bfb558d8ea81067a_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_1ee5a0c2879b421392762cd0b9b1fcc9_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_c1d5310e5c3f4974809d761385c19001_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_daf2f4061aea40e9b08657b38ad3943e_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_modern_eva_loafer_-_black_8a30af78bf5c47b890b6edeb3ad1d4c6_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1__1__94bd33db8a7c4f58b0a74b00e2ee9341_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_b2a17989624d4adfbf3cd716cffc1d26_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_402d86ae5a524ffcbae6b531b7e24607_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "4466EVA1-0",
    price: { saleprice: "1,140,000₫", realprice: "1,900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THEWOLF MINIMAL LOAFER - BLACK",
      href: "products/thewolf-minimal-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_3afa77b4383d41f097ece2fe7fcae526_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_1570d191482c404fbb7252e57c5fcf97_grande.jpg",
      href: "/products/thewolf-minimal-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 374,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_3afa77b4383d41f097ece2fe7fcae526_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_1570d191482c404fbb7252e57c5fcf97_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_4d613ad1703f4caab7f8c537abfc5999_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_6f47c34c52ae4f59ae68634bc41e1a8b_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_1839f02705284c749c1bc51adb8427c3_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_257b67c0fee14db586bea799a85b73c1_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_minimal_loafer_-_black_7962af5e782d408ca4c054808655a5a9_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1__1__32f34a3a4bc8456fa36ab77cd7cb3581_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_6fdfdacc3dc9472d81fa2fda0e40e507_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TWMNLFB-1",
    price: { saleprice: "1,120,000₫", realprice: "1,600,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-30%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THEWOLF MINIMAL LOAFER - TAN",
      href: "products/thewolf-minimal-loafer-tan",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_58413ba0ac244be2bc171cebfad4b57f_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_05b704d06d8b456f994bcc1bb0e70a08_grande.jpg",
      href: "/products/thewolf-minimal-loafer-tan",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 374,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_58413ba0ac244be2bc171cebfad4b57f_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_05b704d06d8b456f994bcc1bb0e70a08_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_e3de9a3e40614360a85ee9d432a9468e_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_57e64142eda243d6924ef30db2a05847_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_3b4ef1b1e8294364a6575b77473fbf3b_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_6fe8e5db805f4e69a9be8185adec798a_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_minimal_loafer_-_tan_ac514fe268a9439590acbb016bdd1a05_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_b2dd450e51294966b96a1eb365dffb8e_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_e895ae686a4d4fb2bd174d47e2370f2b_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TWMNLFT-1",
    price: { saleprice: "1,120,000₫", realprice: "1,600,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-30%",
    colors: { title: "TAN", color: "rgb(225, 179, 130)" },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THE WOLF CHUNKY LOAFER - BLACK",
      href: "products/thewolf-chunky-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_a14878961375442cb4716332dec30bb9_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_54d5cad4ef38449986e4bb2066438126_grande.jpg",
      href: "/products/thewolf-chunky-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 500,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_a14878961375442cb4716332dec30bb9_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_54d5cad4ef38449986e4bb2066438126_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_d8946d8ee5a34781b6c44095007ed94c_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_9b996077f43e4080b2ba680712046f0b_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_1cf90ba313b947edb11e1fc4a8319a31_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_20964ebf0f85428fa998ddef772b61b6_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_chunky_loafer_-_black_ba375abd40d64def90d985ce82cf6d25_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_5773_aa4b8c96a3a24a3da7a9807dc9f26741_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_5833_da4b725d72b74f2c94e3bdef8072edaf_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TWCKLB-0",
    price: { saleprice: "1,500,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THE WOLF CHUNKY LOAFER - BLACK WHITE",
      href: "products/the-wolf-chunky-loafer-black-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_ff078dbeff624589a8682c5478abde4d_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_e3216174f0734db6b5d5649b24529019_grande.jpg",
      href: "/products/the-wolf-chunky-loafer-black-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 500,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_ff078dbeff624589a8682c5478abde4d_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_e3216174f0734db6b5d5649b24529019_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_5bb387195ad94bb997ff719779c2aeae_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_178c3aec03604bc989144ff46829dea8_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_71b83dcb0f7e4a2b81a1a30735b3cca2_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_b1b28198e3b347f4801e9fd70a526033_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_chunky_loafer_-_black_white_aab589c955124578aba06ac9f031a3a5_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_ec07f6881b964a3584b45284a4176434_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_0d981791059b478dbe9e6bae590b3877_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TWCKLBW-0",
    price: { saleprice: "1,500,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "giay-loafer-nam",
    soldout: null,
    title: {
      content: "THE WOLF MARBE CHUNKY LOAFER - BEIGE",
      href: "products/the-wolf-marbe-chunky-loafer-beige",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_d5bcd076598d4ff1bfac78a2037500b5_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_742f24bd944f4c40946301cb2e308a9e_grande.jpg",
      href: "/products/the-wolf-marbe-chunky-loafer-beige",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_d5bcd076598d4ff1bfac78a2037500b5_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_742f24bd944f4c40946301cb2e308a9e_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_01ef3ba9e2c24a75842ab70ff90f4119_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_bb2aac20733b4e4f9b95e0d97a208230_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_75909d798e824776954e72e9105beed5_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_08a31139d3fc440b936cddda15623be1_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_marbe_chunky_loafer_-_beige_c51c02a9cf494d7194129ced884ca647_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TMCKLB-0",
    price: { saleprice: "800,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-60%",
    colors: { title: "BEIGE", color: "rgb(240, 188, 135)" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "giay-slipper-nam",
    soldout: null,
    title: {
      content: "THEWOLF MODERN S SLIPPER - BLACK",
      href: "products/thewolf-modern-s-slipper-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_3584710195fc4ef48067001a7225ff3e_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_86db52914bb94dd6b5a950458fd6c7b2_grande.jpg",
      href: "/products/thewolf-modern-s-slipper-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 340,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_3584710195fc4ef48067001a7225ff3e_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_86db52914bb94dd6b5a950458fd6c7b2_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_f67e87d57da54d668a5ee60e1cdfafc5_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_a9104c4367174c66868add7f381455df_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_a6d28259584d4e8a8bf27f48ee3109bf_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_faf6297b22e6410a87dc2f7e77d9abcb_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-01_797446884d5a4bd486b8b00a32279eb7_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_64e9afaa74484f39ae17031cad1e320e_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_4e31e4f53b5643baafc0ae67080ab1ec_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_8a6477b52a244cf482e0789b99134617_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "4466S-8",
    price: { saleprice: "1,020,000₫", realprice: "1,700,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-slipper-nam",
    soldout: null,
    title: {
      content: "THE WOLF SLIPPER - BLACK",
      href: "products/the-wolf-slipper-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_8bf445168f9d4718a86ecb82a8b369bd_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_a326d4f49f124a0683934be264b05ede_grande.jpg",
      href: "/products/the-wolf-slipper-black",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_8bf445168f9d4718a86ecb82a8b369bd_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_a326d4f49f124a0683934be264b05ede_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_71e2f116cfa8490db7d31cbb113c71cf_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_d6ac1b0b52104ea49cc824206f1f09db_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_e54a488a62f0435db35b21c32caeb034_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_8775c9363e1e4f7c9d96fba7a66412b6_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-03_53fb8b4824a04bfe955d8aa7c8c64b2a_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TWSLP-0",
    price: { saleprice: "900,000₫", realprice: "1,500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "harness-boot-nam",
    soldout: null,
    title: {
      content: "THE ALPHA WOLF HARNESS BOOT - BLACK",
      href: "products/the-alpha-wolf-harness-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_339f5550c94840e1963e277f23b597d2_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_3dcb1f8dec054518a29535d554168beb_grande.jpg",
      href: "/products/the-alpha-wolf-harness-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 572,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_339f5550c94840e1963e277f23b597d2_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_3dcb1f8dec054518a29535d554168beb_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2.2_996d03ae100d4af78949279f03daf228_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_5918d7d360424484a77c5b9bd2c37f82_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_d290951251c64a1889572d721c8c28c4_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_53923f80b2bc4637af9648e5dfe16c6b_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_32ee70f351394ac69651ffb687762751_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/the_alpha_wolf_harness_-_black_cd4760d25391464dab83dfc1371ac5b5_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_69961028bd474ab2bb16a6c50e9a5e4d_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_322811dabd48469c81113a76711be5b1_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "68AWHBB-1",
    price: { saleprice: "1,715,000₫", realprice: "2,450,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-30%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "harness-boot-nam",
    soldout: null,
    title: {
      content: "THE ALPHA WOLF HARNESS BOOT - TOBACCO",
      href: "products/the-alpha-wolf-harness-boot-tobacco",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_007e8fc5c8844a97810aaaf09def3124_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_ac0eaf07a74f4df98808c51fb574bd06_grande.jpg",
      href: "/products/the-alpha-wolf-harness-boot-tobacco",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 490,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_007e8fc5c8844a97810aaaf09def3124_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_ac0eaf07a74f4df98808c51fb574bd06_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2.2_30af278dde3a468583ff8d66f21d12dc_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_361f3e73cb0e47919cc9131bb4d5e03a_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_0da8c4d035444058b2ea82a93ae7d286_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_1a15b987670c4fd28437a871a5cc4c16_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_5692227370f846c89b672ce3728fa3af_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/the_alpha_wolf_harness_boot_-_tobacco_3af96e137c4441559ce1e79bfb31cb9d_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_aed7b347ae2c4f119e42a47f2835f577_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_038593c3a3b846da9757ee045c643423_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "68AWHBT-1",
    price: { saleprice: "1,470,000₫", realprice: "2,450,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "TOBACCO", color: "rgb(183, 117, 67) " },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "combat-boot-nam",
    soldout: null,
    title: {
      content: "THE MARS WOLF HIGH COMBAT BOOT - BLACK",
      href: "products/the-mars-wolf-high-combat-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_83cd49d9ea2444a695f3c20cef4e1efb_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_b1e11d2d71c84773b32dcd3623e5eb31_grande.jpg",
      href: "/products/the-mars-wolf-high-combat-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 725,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_83cd49d9ea2444a695f3c20cef4e1efb_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_b1e11d2d71c84773b32dcd3623e5eb31_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_d6061286f975426fbaed49f0f1f3c205_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_49918cb1ddbe4ea6b10d0ade71db9e16_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_e58b79c3080f46b4bf20496127edeb60_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_e41a0977ff3841c2a7c84e14e5eba0b4_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/8_9bf6f15f53824487ae6f20c06ebf50d1_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_c9b1de1fbd5f4719b9ef588377c13ee9_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-07_10aad4ea40044e31aaca3817207735c0_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/twolf-127__2__fba130632ad4455786ca1c533ef0a86d_master.jpg",
        dataid: "#gal10",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/twolf-174__1__f37075361b124934bbc349eba8031853_master.jpg",
        dataid: "#gal11",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/twolf-192__1__9cad47af967c46659dccb1b00360f19f_master.jpg",
        dataid: "#gal12",
      },
    ],
    code: "TMWHCBBB-1",
    price: { saleprice: "2,175,000₫", realprice: "2,900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "combat-boot-nam",
    soldout: null,
    title: {
      content: "THE MARS WOLF MID COMBAT BOOT - BLACK",
      href: "products/the-mars-wolf-mid-combat-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_af092ede6d6540f5b71887a29cdb43f6_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_a1bfc7711964431cbabd65099e2162c0_grande.jpg",
      href: "/products/the-mars-wolf-mid-combat-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 700,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_af092ede6d6540f5b71887a29cdb43f6_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_a1bfc7711964431cbabd65099e2162c0_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_a0affc88be774d89b58da1818530c181_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_7925955322ac4aec9c96f44b98c373dc_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_ec7ce37e1288467e8e0591a5403049ef_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_c532ab746af84585ae4df547171c2c37_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-08_441752c7522247a78b0438005da3aaf8_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1__2__806a0d422efc4cd9b574faf38e3c4c95_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_69da20e4195b4f48837832218d65265a_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_fc5115a7b651477d8583dbe7acb1f396_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "TMWMCBBB-1",
    price: { saleprice: "2,100,000₫", realprice: "2,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "combat-boot-nam",
    soldout: null,
    title: {
      content: "THE WOLF CHUNKY COMBAT BOOT - BLACK",
      href: "products/the-chunky-combat-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_7e56feee46f748568258dd5f7bd63893_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_4e945e905ba64e7882431a93f3951f60_grande.jpg",
      href: "/products/the-chunky-combat-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 440,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_7e56feee46f748568258dd5f7bd63893_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_4e945e905ba64e7882431a93f3951f60_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_c027b3a243af410787a2cf074624c5c4_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_46e182251a104647a65d791900ace07f_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_0640573b0b324836952d9cd385d56fc5_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_4bb22d3738714da88cfa3e5b5e3a959b_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-05_742194aab04a4ecea4877891a2d6ddf5_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TCKCBBB-0",
    price: { saleprice: "1,320,000₫", realprice: "2,200,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "combat-boot-nam",
    soldout: null,
    title: {
      content: "THE WOLF MARBE CHUNKY COMBAT BOOT - BEIGE",
      href: "products/the-wolf-marbe-chunky-combat-boot-beige",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_b670481718014f179c21b0934189757a_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_5fb5dd3b02274dc9aef2c675433c1a28_grande.jpg",
      href: "/products/the-wolf-marbe-chunky-combat-boot-beige",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_b670481718014f179c21b0934189757a_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_5fb5dd3b02274dc9aef2c675433c1a28_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_d1066fba1dff4a2199d2a1e05a3fbbf0_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_d146e608c89c4076bbae8a257648824e_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_b4330c64910c4eed8b098d98d7c965d1_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_5e571c444217492cad233b1ae33e451d_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-06_60fc220c70794f44961b8b076ec424d3_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TMCKCBBB-0",
    price: { saleprice: "880,000₫", realprice: "2,200,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-60%",
    colors: { title: "BEIGE", color: "rgb(240, 188, 135)" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "oxford-nam",
    soldout: null,
    title: {
      content: "THE BASIC WOLF OXFORD - BLACK",
      href: "products/the-basic-wolf-oxford-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_39e34442ad0b48af9ee06ef37b41d5ab_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_82d9a6f1a12d4112aa1adb1d0f3b414d_grande.jpg",
      href: "/products/the-basic-wolf-oxford-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 350,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_39e34442ad0b48af9ee06ef37b41d5ab_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_82d9a6f1a12d4112aa1adb1d0f3b414d_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_1eda792819794025aaf78203102e986a_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_f2acc42f645e48fd9c52c698ee6b2bc3_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_fd3f904c8dbd4986b32270443ee81487_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_7a24fa1b9ae04fe6845e5e42c1cc6f55_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_a4370e5cce7e4c6abe3a46ffec568d57_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "MWOB-1",
    price: { saleprice: "1,050,000₫", realprice: "1,500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-30%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "thewolf-slide-sandal",
    soldout: null,
    title: {
      content: "THE SEAN WOLF SLIDE SANDAL - BLACK",
      href: "products/the-sean-wolf-slide-sandal-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_313031701a7b4c05af0b5f53fbbf52c8_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_077ecba257b24bc7a5a0f6062fdee184_grande.jpg",
      href: "/products/the-sean-wolf-slide-sandal-black",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_313031701a7b4c05af0b5f53fbbf52c8_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_077ecba257b24bc7a5a0f6062fdee184_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_ebcf5b269cb747d395efe1cf9b8af161_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_9c50122d185845909808bf31b2bf1d67_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_3d43b5ca762b4f69a0fea44aae17cf9d_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_020150bd6c684cf1a7e175f7de49534c_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_866c1429c3214e68877648d1a3aa2444_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/26_1909ca1005c9468689e8825a37dd7593_master.jpeg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/28_3ed72e740a684cd1b943a21ff9bc069b_master.jpeg",
        dataid: "#gal9",
      },
    ],
    code: "TSWSLSDB-1",
    price: { saleprice: "675,000₫", realprice: "900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="36">36</option><option value="37">37</option><option value="38">38</option><option value="39">39</option><option value="40">40</option><option value="41">41</option><option value="42">42</option><option value="43">43</option><option value="44">44</option>',
  },
  {
    type: "thewolf-slide-sandal",
    soldout: null,
    title: {
      content: "THE SEAN WOLF SLIDE SANDAL - BROWN",
      href: "products/the-sean-wolf-slide-sandal-brown",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_ee08296bc9c9459a80e1b19e41c7a980_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_dc3a58ca171249b1be863444cdcfc62c_grande.jpg",
      href: "/products/the-sean-wolf-slide-sandal-brown",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_ee08296bc9c9459a80e1b19e41c7a980_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_dc3a58ca171249b1be863444cdcfc62c_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_523f3408fd404762a30e7b4ef4982ffb_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_a11c4def9d7b4fb5a3246f48e1dfdf84_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_067d70808f3a48678a280eb0771b5017_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_5e599c71d8f54ddc9c033ac65f5964b8_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/21_040784196b2140c3bef22662d47276d0_master.jpeg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/24_896d89085ecc4870ba69de4999421e7a_master.jpeg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/25_8532221019b2411d9a2c53e507de4edf_master.jpeg",
        dataid: "#gal9",
      },
    ],
    code: "TSWSLSDB-1",
    price: { saleprice: "675,000₫", realprice: "900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BROWN", color: "rgb(110, 74, 74) n" },
    sizes:
      '<option value="36">36</option><option value="37">37</option><option value="38">38</option><option value="39">39</option><option value="40">40</option><option value="41">41</option><option value="42">42</option><option value="44">44</option>',
  },
  {
    type: "thewolf-slide-sandal",
    soldout: null,
    title: {
      content: "THE SEAN WOLF SLIDE SANDAL - WHITE",
      href: "products/the-sean-wolf-slide-sandal-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_c98888e8f84a481587ddbc86644c87a8_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_a34e9084a3fd425787284e451b285afc_grande.jpg",
      href: "/products/the-sean-wolf-slide-sandal-white",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_c98888e8f84a481587ddbc86644c87a8_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_a34e9084a3fd425787284e451b285afc_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_4ced73539a4d4e538ea1fc06d6854eb3_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_a689d4c6f9964c95b13ca580cbd7535c_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_f9afaa13110d4ccbb6416651ce501e4e_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_60f764ce975b4981b5072da431f5c38d_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_0dc0c151b3ec42c8b2d40028f984ec67_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/31_4061865a803b4702a2bad50f841c31df_master.jpeg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/33_170fffc4b1c34facb03801c137d1cbfd_master.jpeg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/35_d00f35ec29024cda8092ea0a41b37fdb_master.jpeg",
        dataid: "#gal10",
      },
    ],
    code: "TSWSLSDW-1",
    price: { saleprice: "675,000₫", realprice: "900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="36">36</option><option value="37">37</option><option value="38">38</option><option value="39">39</option><option value="40">40</option><option value="41">41</option><option value="42">42</option><option value="43">43</option><option value="44">44</option>',
  },
  {
    type: "chelsea-boot-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF CHUNKY CHELSEA BOOT - BLACK",
      href: "products/the-lady-wolf-chunky-chelsea-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_1e62e3ab904a434585a81f970ff42074_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_e9d6df8b873e4a35bde3eb1fcbae2cb3_grande.jpg",
      href: "/products/the-lady-wolf-chunky-chelsea-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 500,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_1e62e3ab904a434585a81f970ff42074_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_e9d6df8b873e4a35bde3eb1fcbae2cb3_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_524d592c968240c8ad42e4b5e6961b7a_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_6912b896ce7b48b482c97720736649b9_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_3a51c92112e14640b69e96ebf47bfb80_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_eb57354316d948489a89e17c9c9cdcc4_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/13_4833999162ff4536afae5b8cd35d2400_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_5623__1__a62b5b52a1684a309695a5473fb2a331_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_5722__2__21ab3b7d0b6c4e04af061f602fbbc969_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TLCKCSBB-0",
    price: { saleprice: "1,500,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF MARBE CHUNKY CHELSEA BOOT - BEIGE",
      href: "products/the-lady-wolf-marbe-chunky-chelsea-boot-beige",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_6427519963ca438fb31f92dc3528163b_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_71305bf37dd842929f3fe7b2443370cf_grande.jpg",
      href: "/products/the-lady-wolf-marbe-chunky-chelsea-boot-beige",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_6427519963ca438fb31f92dc3528163b_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_71305bf37dd842929f3fe7b2443370cf_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_b4c21a284e114ca2b0288809b1109781_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_c6857812a98b4472bc0cfa740d7e41cd_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_d89cf90cbe734169939531a6287b8714_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_67b4f9d6f1374dc0827dd4cba5cf2bd1_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/14_53bac22d72594d23b80114f4225bd322_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session1366__2__1c59f5001b1c427f8e6c5313ffa5d07f_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session1352__1__1a02ce41e09d4b65b854d38a2265765e_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TLWMCBB-0",
    price: { saleprice: "800,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-60%",
    colors: { title: "BEIGE", color: "rgb(240, 188, 135)" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF MODERN CHELSEA BOOT - BLACK",
      href: "products/the-lady-wolf-modern-chelsea-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_396ed936139048f896718135c8c729bc_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_dc01ac019ae1463f8c678ed949c7c6bd_grande.jpg",
      href: "/products/the-lady-wolf-modern-chelsea-boot-black",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_396ed936139048f896718135c8c729bc_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_dc01ac019ae1463f8c678ed949c7c6bd_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_479387755c454b88a1263534477e39a2_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_e2de709c67704aef8394270045c49309_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_b256b9692571449780c51e1e794dcc0d_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_c9ac2e8c28494dde8f56d6be1ce20933_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/15_e732dd587874497a81afcdb562ca7125_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session5628_7078ec513c1f4a969b842b0953b8f24b_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session5452_315885e572794c2aa878c66fc112bcd7_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TWMCHBB-0",
    price: { saleprice: "900,000₫", realprice: "1,500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option>',
  },
  {
    type: "chelsea-boot-nu",
    soldout: null,
    title: {
      content: "THE MARS LADY WOLF CHELSEA BOOT - BLACK",
      href: "products/the-mars-lady-wolf-chelsea-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_960bd5794f1a40cc950ce51d8d59be30_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_44f851ec4cd54c52a6f039a07a8d0af7_grande.jpg",
      href: "/products/the-mars-lady-wolf-chelsea-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_960bd5794f1a40cc950ce51d8d59be30_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_44f851ec4cd54c52a6f039a07a8d0af7_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_bf2b602617884aa59524ab7b85ac22f6_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_d3d184fa9d2d4d19b3d23e5244139bd7_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_6b926b9f4edc4e29a176f6d8fe6e2e0b_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_fe0b827b126f4a9abc3ec5aa23abda2f_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/8_3f7d2e47f4314a219290e2039abae8c5_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TMWCSBB-1",
    price: { saleprice: "1,800,000₫", realprice: "2,400,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nu",
    soldout: null,
    title: {
      content: "THE MARS LADY WOLF CHELSEA BOOT - BLACK SUEDE",
      href: "products/the-mars-lady-wolf-chelsea-boot-black-suede",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_bf675c85e02e44cd849e8f3d20225dcd_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_b68491d6b96545b799fca47bbd5a8a67_grande.jpg",
      href: "/products/the-mars-lady-wolf-chelsea-boot-black-suede",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_bf675c85e02e44cd849e8f3d20225dcd_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_b68491d6b96545b799fca47bbd5a8a67_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_96521813f69f4570bc2f3ed88d9340a8_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_4c4cf00040294ed09052705bbba86228_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_04b88fa0c8ad4909aec1a68ad1af8580_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_9bd7d4ccef454deaa23f871d0f1819d0_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_5478c927f3474790b6c1e5d1bb062f39_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TMWCSBBS-1",
    price: { saleprice: "1,800,000₫", realprice: "2,400,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nu",
    soldout: null,
    title: {
      content: "THE MARS LADY WOLF CHELSEA BOOT - TAN SUEDE",
      href: "products/the-mars-lady-wolf-chelsea-boot-tan-suede",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_b97a397b92f2430ab99de27c477fdf2d_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_bec6849f88de4b72ad9883b0f03d2714_grande.jpg",
      href: "/products/the-mars-lady-wolf-chelsea-boot-tan-suede",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_b97a397b92f2430ab99de27c477fdf2d_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_bec6849f88de4b72ad9883b0f03d2714_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_d8bc5236ca1047adb0837860dc9172ba_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_d45d7f1220e74e0980fe3c150b00e697_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_7039a2ab831a4e22b8434479621d255e_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_f4c7174d82104caba9b59ec9fb37cc62_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_df0dd06956b74097a793ea0f23509080_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_1b7bd21322b546b2ad44fbe49a2a3302_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_80c5fcb3690042249525ee58b8839b29_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_48c5ea794491440dbd60219004f1cf24_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "TMWCSBTS-1",
    price: { saleprice: "1,800,000₫", realprice: "2,400,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "TAN", color: "rgb(225, 179, 130)" },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "chelsea-boot-nu",
    soldout: null,
    title: {
      content: "THE MARS LADY WOLF CHELSEA BOOT SPECIAL EDITION - BLACK",
      href: "products/the-mars-lady-wolf-chelsea-boot-special-edition-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_46304735409045fbb077ad8fcc87d4ed_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_6ee39217cf0243cc97ef3e50a1934f3a_grande.jpg",
      href: "/products/the-mars-lady-wolf-chelsea-boot-special-edition-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 867,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_46304735409045fbb077ad8fcc87d4ed_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_6ee39217cf0243cc97ef3e50a1934f3a_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_c21b083a7d94478ea426c60ebed1e2ee_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_cca7f72c5fa7434781f8cfd4e697ab28_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_de5a2c85cf40494abce7c068d1a31070_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_9e853e0037fe4b0fbc818abbf6f72879_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_cc75809775614f32aee97073267154fa_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_73c533736fc948f7b622735f7b5d35b6_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_3702dc4da3c94705b1ab1799be54cd87_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3__2__7b462e8502fa4ad98d6e10e847755e66_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "TMWCSBSEB-1",
    price: { saleprice: "2,600,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "combat-boot-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF CHUNKY COMBAT BOOT - BLACK",
      href: "products/the-lady-wolf-chunky-combat-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_d4efeaf52bf64b02bf224a10d56340b1_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_219b1bc13cbe4a21ad19676830051e64_grande.jpg",
      href: "/products/the-lady-wolf-chunky-combat-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 440,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_d4efeaf52bf64b02bf224a10d56340b1_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_219b1bc13cbe4a21ad19676830051e64_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_f9b4b3b1779e48a1a46028527a24ad20_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_5e31c3491fb74f9692a7b02d138b1bc7_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_c16330827c8a4dd0ae1ca7a12d5203c0_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_25d1f734e586461a98278f24ba1219c2_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-05_a4b9e787f9824ecbabc10ab431526aaf_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session25249_1e5712c1e0be4b42828c3d447a27efad_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session25311_200da623fb14421ca7447b7dd78f6588_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TLCKCBBB-0",
    price: { saleprice: "1,320,000₫", realprice: "2,200,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "combat-boot-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF MARBE CHUNKY COMBAT BOOT - BEIGE",
      href: "products/the-lady-wolf-marbe-chunky-combat-boot-beige",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_da1e999790294cf3b6de513851150fc8_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_ad0d4aa2272d4da79e1014e7641cc863_grande.jpg",
      href: "/products/the-lady-wolf-marbe-chunky-combat-boot-beige",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_da1e999790294cf3b6de513851150fc8_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_ad0d4aa2272d4da79e1014e7641cc863_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_1172ce89c3bc4ec18c76453bdd0fe386_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_931580ebb0544835831ce3682cb5b5cb_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_1fec7a91c0654247be65c043b184c14b_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_69c1f4a8b28a4a70a7d19b828186f1e4_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-06_ee02e1e9c0534ac1bf88ac6b5c8ae743_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session1532__2__2a63c48fa8984e3eb49576af51cc9344_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session1572__1__52ae2405eb054e1284328213167dbdcc_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TLMCKCBBB-0",
    price: { saleprice: "880,000₫", realprice: "2,200,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-60%",
    colors: { title: "BEIGE", color: "rgb(240, 188, 135)" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "combat-boot-nu",
    soldout: null,
    title: {
      content: "THE MARS LADY WOLF HIGH COMBAT BOOT - BLACK",
      href: "products/the-mars-lady-wolf-high-combat-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_764010d9901243a19b95a7a990abd499_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_31428d0b266246e1bf449be012f99b60_grande.jpg",
      href: "/products/the-mars-lady-wolf-high-combat-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 725,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_764010d9901243a19b95a7a990abd499_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_31428d0b266246e1bf449be012f99b60_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_8c1ae901e29c4897a1372d33908599c8_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_ee43c08b224249db89d2bdc9109b3c9f_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_f7812a3a0fe641a990863e63b2bb089f_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ad8ababa8e0b4214988386596cdf097b_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/8_e119d56728c14137a124368373c74b0b_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_30ec18df461f4d5b8fd23e9bc3e17f25_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-07_667d3a4b17024ec79aef49bab0b5f0be_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/twolf-127__2__68889837c0f74f4582077134d4e46631_master.jpg",
        dataid: "#gal10",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/twolf-174__1__19c62ed7da5e473889af335bc89b5009_master.jpg",
        dataid: "#gal11",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/twolf-192__1__36cf73230e5b4ad3bd01ed51c402f0bf_master.jpg",
        dataid: "#gal12",
      },
    ],
    code: "TMWHCBBB-1",
    price: { saleprice: "2,175,000₫", realprice: "2,900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "combat-boot-nu",
    soldout: null,
    title: {
      content: "THE MARS LADY WOLF MID COMBAT BOOT - BLACK",
      href: "products/the-mars-lady-wolf-mid-combat-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_35a828c87bcd46edbf4f06fdb6e7b4e1_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_1613338fe66b42058b8db77770480ea5_grande.jpg",
      href: "/products/the-mars-lady-wolf-mid-combat-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 700,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_35a828c87bcd46edbf4f06fdb6e7b4e1_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_1613338fe66b42058b8db77770480ea5_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_5d2a6a0d85c34da1898e66e74b22b5e5_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_aa6ccf860b1a4f15ab18ecd1aed877e3_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_aeca0c95165b4a74a8535b19775cb6e7_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_d87144cb1ff746e8a3f8c71852fe05b4_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-08_fc7c02472ea347be859c57a76b151751_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_feef3ac75cd440ec93e2257b0865cbc0_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_62c8563ca86c4fcaab02e23f01e84723_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_6fd8b55831414cb3a08164fa415ada68_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "TMWMCBBB-1",
    price: { saleprice: "2,100,000₫", realprice: "2,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "derby-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF MODERN EVA DERBY - BLACK",
      href: "products/the-lady-wolf-modern-eva-derby-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_0a7f06c49c05430da51426b1cad2b19e_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_dd4fb8ece7534ea688a36b073917c22b_grande.jpg",
      href: "/products/the-lady-wolf-modern-eva-derby-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 420,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_0a7f06c49c05430da51426b1cad2b19e_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_dd4fb8ece7534ea688a36b073917c22b_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_5aaaf620bc2c443aa60723682a3dbe81_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_035372f7679047ae8c162c780210b26f_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_d2cf8242cde442589767ea67e986653d_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_0c3aaa15d8d0492589665aee26855bff_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/8_40b8a587d3a24cef9a4efb1e046224b9_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "4466EVA-0",
    price: { saleprice: "1,260,000₫", realprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-30%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "derby-nu",
    soldout: null,
    title: {
      content: "THE MARS LADY WOLF DERBY - BLACK",
      href: "products/the-mars-lady-wolf-derby-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_865e82f064cb418d91d08df363d6457a_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_462b329fc3f54f9484bb9bcc704f32ca_grande.jpg",
      href: "/products/the-mars-lady-wolf-derby-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 613,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_865e82f064cb418d91d08df363d6457a_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_462b329fc3f54f9484bb9bcc704f32ca_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_eb651c5d747e41958f8ae9415741faad_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_40411d40f802442fb1838d87565d2082_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_9f310dd52da0425390626585f4322599_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_8da235bacd1a41c197404cbfaf104abf_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_5ead1516929645abbf8e09cd65d65fcf_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_fe163e7f6bc445be9710ef6257a76b0b_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_5b245c666c54493dadfe76f7ce5e1864_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TMWDBB-1",
    price: { saleprice: "1,837,500₫", realprice: "2,450,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "derby-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF CHUNKY DERBY - BLACK WHITE",
      href: "products/the-lady-wolf-chunky-derby-black-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_7146e17d3f724f99978698b1829ee223_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_b10e4808300a401dadc2aef5cc353fc5_grande.jpg",
      href: "/products/the-lady-wolf-chunky-derby-black-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 500,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_7146e17d3f724f99978698b1829ee223_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_b10e4808300a401dadc2aef5cc353fc5_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_989bfa69bbbc4103a199d1c7cec91683_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_d725ea79a7fd4258a7b81ec796abb7ed_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_785f6e2cb3a94552b7492e0eb02c9f5c_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_f34648f8c36c4a248a071a301363f1c4_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_d951bf8226a740fc80fce03fa7e75c37_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TLCKDBBW-0",
    price: { saleprice: "1,500,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "derby-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF MARBE CHUNKY DERBY - BEIGE",
      href: "products/the-lady-wolf-marbe-chunky-derby-beige",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_5d3ac2ebb3854b659516ec5d390bc811_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_21cf1cf044524d3096b7608441bcb1d0_grande.jpg",
      href: "/products/the-lady-wolf-marbe-chunky-derby-beige",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_5d3ac2ebb3854b659516ec5d390bc811_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_21cf1cf044524d3096b7608441bcb1d0_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_24116bf3109b45f884ae3c712342ba1b_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_3eb5d92095b841da9edba75b51143ac0_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_563c7968b4e8470b9c66c03dc181fa01_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_adcde99397f144a0b0686b9cc18b29f7_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_bcbaac45d0db4b3d9162744c9d390961_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session1611__1__3f68468de8b746149c0a4e7961c30082_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session1655__1__0713bdaa90ab49c7b3c042eb14d536bf_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TLMCKDBB-0",
    price: { saleprice: "800,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-60%",
    colors: { title: "BEIGE", color: "rgb(240, 188, 135)" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "derby-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF CHUNKY DERBY 4 EYELET - BLACK",
      href: "products/the-lady-wolf-chunky-derby-4-eyelet-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_d353d8e63e1b4168a56b399cfdde181b_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_2343a6096480428b9abf7cf5e5089893_grande.jpg",
      href: "/products/the-lady-wolf-chunky-derby-4-eyelet-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 400,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_d353d8e63e1b4168a56b399cfdde181b_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_2343a6096480428b9abf7cf5e5089893_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_06250982ea724768aff02aac97bb93c9_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_8327a16cb8634d12bd5e1120b23edd6c_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_5c295809475e4540bbefcf8fc0c54eac_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_7fadf2e3025c4322b472b13e3a358717_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_60e2e3947d03477aad8804c1c36623d4_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_5775__1__c029931ef14c4adba012506e130a769c_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_5780__1__f6a637e852c6464bbc9c6518bd969f46_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TLWCKD4EB-0",
    price: { saleprice: "1,200,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "derby-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF CHUNKY DERBY - BLACK",
      href: "products/the-lady-wolf-chunky-derby-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_2718b52a6d594a2baf87abc6bbaf7838_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_4e77dc31f1ac478f85b839a374a40f75_grande.jpg",
      href: "/products/the-lady-wolf-chunky-derby-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 500,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_2718b52a6d594a2baf87abc6bbaf7838_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_4e77dc31f1ac478f85b839a374a40f75_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_cbddd1baa5aa404ca4bd9845dd212805_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_fc9f0df93c574dc49ccdc7742310a0de_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_fee51255fedc478f8064c21f227569f3_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_f168e1fb45cc445ea7199f98cd14eed5_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_59f8e1e99a674053b1592fd8a037e9ce_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session5300_159182e8995842c7938702631bd9849f_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session5371_3ab45af8b3ee41109682ffa8bd12a600_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TLWCKDBB-0",
    price: { saleprice: "1,500,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "derby-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF MODERN DERBY – BLACK",
      href: "products/the-lady-wolf-modern-derby-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_f9dd67983e38400d92e6769f8df7ac4d_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_b82288dc86a648d3996ab42e85f30edd_grande.jpg",
      href: "/products/the-lady-wolf-modern-derby-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 400,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_f9dd67983e38400d92e6769f8df7ac4d_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_b82288dc86a648d3996ab42e85f30edd_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_3cdcb13dddac440fabf02e4814c82505_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_0c56cee8bad84eef935cd420ebdbd3c9_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_9b598cf459304722942fbd2e7dbf40b6_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_838231fca868480b8d1d6ffd49b0f758_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_218d8869b71f4c2da76020a79e144a55_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "49MDB-0",
    price: { saleprice: "1,200,000₫", realprice: "1,600,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nu",
    soldout: null,
    title: {
      content: "THE SEAN LADY WOLF CHUNKY LOAFER - BLACK OFF WHITE",
      href: "products/the-sean-lady-wolf-chunky-loafer-black-off-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1.2_61a08656ef6847659077e521075876f8_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2.2_acae7408ae5c4571b8ece2ab92d5d1c1_grande.jpg",
      href: "/products/the-sean-lady-wolf-chunky-loafer-black-off-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 667,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1.2_61a08656ef6847659077e521075876f8_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2.2_acae7408ae5c4571b8ece2ab92d5d1c1_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_f34cbc20494a46e0b17f6240ac930ec6_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_5dcc5a58c2da492c8839be9c9b7513a4_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_efc035f4b285453f973b6047a1695829_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/ban_sao_cua_7_86ab53f08373434484ed96d2e1d850eb_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_6ee98b5a6c7e43e8a7165ab2e2ac2fb1_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWCKLFBW-1",
    price: { saleprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "giay-loafer-nu",
    soldout: null,
    title: {
      content: "THE SEAN LADY WOLF CHUNKY LOAFER - BLACK",
      href: "products/the-sean-lady-wolf-chunky-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1.2_6e24c837eaef44b880da9b0685117d54_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2.2_d5bbe3a52a034360880d467d67f977e2_grande.jpg",
      href: "/products/the-sean-lady-wolf-chunky-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 667,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1.2_6e24c837eaef44b880da9b0685117d54_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2.2_d5bbe3a52a034360880d467d67f977e2_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_b998627309294060ad83d04bc338fc2c_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_6cb9050578b6476ba04bb514465b45e0_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_9ba40c8cd54741ab9928f4563ff8c506_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_3ee59efc3de94cecb07b0dbc035b1d0d_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/ban_sao_cua_7_5b3941295e094caba26b9cc0f4af802f_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWCKLFB-1",
    price: { saleprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 RU">43 RU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "giay-loafer-nu",
    soldout: null,
    title: {
      content: "THE SEAN LADY WOLF MODERN LOAFER - BLACK OFF WHITE",
      href: "products/the-sean-lady-wolf-modern-loafer-black-off-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_736b2fe1da7843079149122eb8cad468_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_0f6158ec9aca4fbf8440ed75d1f46177_grande.jpg",
      href: "/products/the-sean-lady-wolf-modern-loafer-black-off-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_736b2fe1da7843079149122eb8cad468_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_0f6158ec9aca4fbf8440ed75d1f46177_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_e31c29d6d5814f49ad756860dd7f66b3_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_37a3bfba36e1477682e46e6229e0d702_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_1a454a5e40c54cf3a5ad7abe3e5cfb20_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_147a91ba38394facba0593c05c124ef4_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_7d42f432781e4e0e99612813c97347fc_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWMDLFBW-1",
    price: { saleprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nu",
    soldout: null,
    title: {
      content: "THE SEAN LADY WOLF MODERN LOAFER - BLACK",
      href: "products/the-sean-lady-wolf-modern-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_0ddd993541b44b289aa9b96b0883f1f9_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_5bbf10f98d9144c2a79b2fc41766dcc8_grande.jpg",
      href: "/products/the-sean-lady-wolf-modern-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_0ddd993541b44b289aa9b96b0883f1f9_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_5bbf10f98d9144c2a79b2fc41766dcc8_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_091de429eb9f4615b184c4f5f0ea91be_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_8d87e6e11b26401b9a6e8ea2734d9d9b_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_da5e3c2dede14a47aeb69f8d11bee6f7_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_be781873005f42ef8ae2b9f338bb1c75_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_dd337ef8441740dc9e671eae7d0b1f4a_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWMDLFB-1",
    price: { saleprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF MODERN EVA LOAFER - BLACK",
      href: "products/the-lady-wolf-modern-eva-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_f87d081a3a5d4b34afc5f8cabbccf9e0_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_a415cd961421482c8d152f7a98faf89e_grande.jpg",
      href: "/products/the-lady-wolf-modern-eva-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 380,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_f87d081a3a5d4b34afc5f8cabbccf9e0_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_a415cd961421482c8d152f7a98faf89e_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_941d42aeeabc47429475285a658dff82_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_c26696fbca96402789c23697889bd327_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_fdc033c43f35487a8156031e58fba204_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_4c02f51ae2e943b2bbdebd9cf6e16392_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_modern_eva_loafer_-_black_e7671d0a00584716b857ea7bbe0a33f5_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_5449_256a2a20c5ae49e1a49962d93b07c0a6_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_5503__2__fdf825add02946d0b590a8a8dad0db2d_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "4466EVA1-0",
    price: { saleprice: "1,140,000₫", realprice: "1,900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF CHUNKY LOAFER - BLACK WHITE",
      href: "products/the-lady-wolf-chunky-loafer-black-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_c498afffb17842d78451b10b6094f115_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_ca2b5e7a2b004a0b97a3fd87abed4e42_grande.jpg",
      href: "/products/the-lady-wolf-chunky-loafer-black-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 500,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_c498afffb17842d78451b10b6094f115_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_ca2b5e7a2b004a0b97a3fd87abed4e42_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_801b318e55064c3698bb30703adb9473_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_1f79885b31964c599e002f730887a4cc_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_f596eb7d17cb4244aa3badd24ad849ad_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_c85f507337534c0b86acab9d87d9b62f_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_chunky_loafer_-_black_white_6d8379fd460440abb10a2ab19d18cfaf_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/bin03164__1__669e135cc47142188dab52cbde5f6a41_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/bin03084__1__4c856926bfc448dcaca332f71cdde409_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TLWCKLBW-0",
    price: { saleprice: "1,500,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "giay-loafer-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF MARBE CHUNKY LOAFER - BEIGE",
      href: "products/the-lady-wolf-marbe-chunky-loafer-beige",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_bf00b318297a45039c39e21cb527fa32_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_b1f69d9d07f0423ab1121b2946123261_grande.jpg",
      href: "/products/the-lady-wolf-marbe-chunky-loafer-beige",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_bf00b318297a45039c39e21cb527fa32_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_b1f69d9d07f0423ab1121b2946123261_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_7f749bbb4484412383a977b286dfdf10_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_0cb02bc107784bb3abfba3649778cd9a_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_0fc30eaa34554444ba6a921eb4b06f39_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_0e895e71044640e8bd2d34a237b88de3_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_marbe_chunky_loafer_-_beige_ba628aea793f4654b9eb557ca6179013_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session1441__1__9f751d3044f541c784d115fa36ff6bc1_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session1378_1__1__308ac71f5d854582bd880ada728310cf_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TLMCKLB-0",
    price: { saleprice: "800,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-60%",
    colors: { title: "BEIGE", color: "rgb(240, 188, 135)" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "giay-loafer-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF CHUNKY LOAFER - BLACK",
      href: "products/the-lady-wolf-chunky-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_d3f0af3b89bf46d1a7950cd18ac53966_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_c9aac0aa5e5948cca218d3707854ccf3_grande.jpg",
      href: "/products/the-lady-wolf-chunky-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 500,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_d3f0af3b89bf46d1a7950cd18ac53966_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_c9aac0aa5e5948cca218d3707854ccf3_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_ae604fa980564278af5ddc24b9a664d7_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_c9714a29c46441ef81e06c002a6bdac3_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_c93bae7b461245d4a69ea76146a923cf_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ab44f3b820884acc8e33ccd8dbf544c1_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_chunky_loafer_-_black_6c634574817045a8a012ff5df8600122_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session25116_cecdbb42ef1e445b970805bf89bddd3e_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session25175__1__827136beb5f14bd2ade3ca90afed32af_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TLWCKLB-0",
    price: { saleprice: "1,500,000₫", realprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "giay-loafer-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF PENNY LOAFER - BLACK WHITE",
      href: "products/the-lady-wolf-penny-loafer-black-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_fc4c2d993dbd4b1189e82201c8557db1_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_13c082db02024f3abce6f19371314501_grande.jpg",
      href: "/products/the-lady-wolf-penny-loafer-black-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 425,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_fc4c2d993dbd4b1189e82201c8557db1_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_13c082db02024f3abce6f19371314501_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_728cb407b6d347fba3deea57fed1aac5_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_bc5af3ecebc6424dab52e78e41bc8991_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_f079671d4c0a4e7d8ac32363a2cce985_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_9733ac476a7b44049b96788e417862c6_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/the_wolf_penny_loafer_-_black_white_4c2b2d8d315142d58fe140711385f8e4_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session5820_740aec164acc414b98ec65cfd6645ae1_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session5831_1428d72615c44c38b93c020cb156d621_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "SWPNLFW-0",
    price: { saleprice: "1,275,000₫", realprice: "1,700,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-loafer-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF PENNY LOAFER - BLACK",
      href: "products/the-lady-wolf-penny-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_42abc9e11cda44df8afa5475c2c0f1f3_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_d1a17663619642648472a0fcc6ab680f_grande.jpg",
      href: "/products/the-lady-wolf-penny-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 425,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_42abc9e11cda44df8afa5475c2c0f1f3_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_d1a17663619642648472a0fcc6ab680f_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_2302329d12d54315a56dc28214ce18a5_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_4522e38329ca4b81a0384729c226e5c4_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_c92761ffb2074b0a8320ce269893596f_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_566779fc142a4ce8a21dfce56f42f2c2_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_penny_loafer_-_black_13db458a91e14ba58d935eb366552046_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session5699_f15d0d98910f42b2b95021bc7828d1ba_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session5716_6c44c2c922ad431597bbbb9eba90b3ff_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "SWPNLFB-0",
    price: { saleprice: "1,275,000₫", realprice: "1,700,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-slipper-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF MODERN S SLIPPER - BLACK",
      href: "products/the-lady-wolf-modern-s-slipper-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_2b73bc14dd98462ea9333dec065744ca_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_434a0d58deeb476cbecfaf42d2507562_grande.jpg",
      href: "/products/the-lady-wolf-modern-s-slipper-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 340,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_2b73bc14dd98462ea9333dec065744ca_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_434a0d58deeb476cbecfaf42d2507562_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_bc046af2b99b442e9cdc2dee50704099_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_aa6600f0b254418d9be0d87aa1954e1d_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_0b8d366b47534e8bbd09e46855d675d5_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_598f411f17bc4391bd5ec7684b457423_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-01_9d6cf05100ed4e16af176133ffcd5a33_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_5147__1__5f20537f87704e188100ac5af7d27a11_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_5341__1__e72c75730d9e405394f15d494b0c1d7b_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "4466S-8",
    price: { saleprice: "1,020,000₫", realprice: "1,700,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "giay-slipper-nu",
    soldout: null,
    title: {
      content: "THE LADY WOLF SLIPPER - BLACK",
      href: "products/the-lady-wolf-slipper-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_4ceae52949db46b5a3df1cc0d3798e51_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_49ac5e652e094ecd98d260bc347ab6ce_grande.jpg",
      href: "/products/the-lady-wolf-slipper-black",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_4ceae52949db46b5a3df1cc0d3798e51_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_49ac5e652e094ecd98d260bc347ab6ce_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_1263e834bf9f4378b87bb4d6ac061605_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_d684bce985f54cea82e2e077d288e1f1_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_a42b36cafcb545c89a1c52c2ff300c42_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_1eeb93bda0f348a18cf4ea3b219831b5_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-03_3e328dfc8ea048249027219c7cdd153f_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session5396_9470365c26fa4d38bf148c459e268e07_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/untitled_session5434_a7320f350e8946178bc7dbda2742b761_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "SWSLP-0",
    price: { saleprice: "900,000₫", realprice: "1,500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "the-lady-wolf-slide-sandal",
    soldout: null,
    title: {
      content: "THE SEAN LADY WOLF SLIDE SANDAL - BLACK",
      href: "products/the-sean-lady-wolf-slide-sandal-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_8f233260b0fa4d749c0205ee64138c92_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_e8cea2373b1d4badad0ad3a068dab289_grande.jpg",
      href: "/products/the-sean-lady-wolf-slide-sandal-black",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_8f233260b0fa4d749c0205ee64138c92_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_e8cea2373b1d4badad0ad3a068dab289_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_6b5229fd567141cabdeca7bfd4907524_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_7633e121fa67485c8474b9789957270c_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_7ff3a1e49dde4977a74a37a2e9b9505e_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_d8d8f01b8e7d44088dace98ff35c7bd3_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_04360944753d4d0d8d22d475246d36e4_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/11_e063376bfcd24600825219a8b143335d_master.jpeg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/12_cf18b894f16c4a40994d4f7fa2fd1cf2_master.jpeg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/15_d9ae5f83a9af4a5fbee9c23bf8b51007_master.jpeg",
        dataid: "#gal10",
      },
    ],
    code: "TSWSLSDB-1",
    price: { saleprice: "675,000₫", realprice: "900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="36">36</option><option value="37">37</option><option value="38">38</option><option value="39">39</option><option value="40">40</option><option value="41">41</option><option value="42">42</option><option value="43">43</option><option value="44">44</option>',
  },
  {
    type: "the-lady-wolf-slide-sandal",
    soldout: null,
    title: {
      content: "THE SEAN LADY WOLF SLIDE SANDAL - BROWN",
      href: "products/the-sean-lady-wolf-slide-sandal-brown",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_9e3b99e90ecc495abab3385ed7baa7c6_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_f0d555f530164c0580bd0f92e6e0b48e_grande.jpg",
      href: "/products/the-sean-lady-wolf-slide-sandal-brown",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_9e3b99e90ecc495abab3385ed7baa7c6_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_f0d555f530164c0580bd0f92e6e0b48e_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_cf8f5c75906b4ebca552e289f67be268_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_d69bd986b9d549b69a2bd5826f525883_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_0b6cf818786e478a8eade8cee35eda45_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_43ccc190773f4de8831793d7a59cf85d_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_81de2b0ae0e443149c0cfeaa9e19add9_master.jpeg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/9_2ef8a3a7d1ce4af5aa18c37609926ec6_master.jpeg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/10_17b4068829ec487798e3c6950a7cd049_master.jpeg",
        dataid: "#gal9",
      },
    ],
    code: "TSWSLSDB-1",
    price: { saleprice: "675,000₫", realprice: "900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BROWN", color: "rgb(110, 74, 74) n" },
    sizes:
      '<option value="36">36</option><option value="37">37</option><option value="38">38</option><option value="39">39</option><option value="40">40</option><option value="41">41</option><option value="42">42</option><option value="43">43</option><option value="44">44</option>',
  },
  {
    type: "the-lady-wolf-slide-sandal",
    soldout: null,
    title: {
      content: "THE SEAN LADY WOLF SLIDE SANDAL - WHITE",
      href: "products/the-sean-lady-wolf-slide-sandal-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_1763899f520a4eb9aeb605d740acdae8_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_f5c014110fad4e829edde3d1c5ae2630_grande.jpg",
      href: "/products/the-sean-lady-wolf-slide-sandal-white",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_1763899f520a4eb9aeb605d740acdae8_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_f5c014110fad4e829edde3d1c5ae2630_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_ae5b52623a8c4c1f93850e6d51cff000_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_461102fdc77442e298f9e7cca946ac0b_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_45f66feb334c470c80bcd729f74d88f5_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_42cea64afffb483b8794e203658ffad2_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_cce75115d9144431935ee7a49cdbb301_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/16_01705e7cc5264da5938dde05ddd4f9d8_master.jpeg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/18_3371dee916b546e8942c19dad9cd845d_master.jpeg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/20_1441a528ce9844b78400f41657dbfdfd_master.jpeg",
        dataid: "#gal10",
      },
    ],
    code: "TSWSLSDW-1",
    price: { saleprice: "675,000₫", realprice: "900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="36">36</option><option value="37">37</option><option value="38">38</option><option value="39">39</option><option value="40">40</option><option value="41">41</option><option value="42">42</option><option value="43">43</option><option value="44">44</option>',
  },
  {
    type: "the-sean-wolf-belts",
    soldout: null,
    title: {
      content: "THE SEAN WOLF BELT - BLACK",
      href: "products/the-sean-wolf-belt-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_ba5398799ded4c4f9966902f635330b4_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_be90b9ee66134cffbef6e124738825c3_grande.jpg",
      href: "/products/the-sean-wolf-belt-black",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_ba5398799ded4c4f9966902f635330b4_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_be90b9ee66134cffbef6e124738825c3_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_2fe4ae129b8f484d8a0ab9b41e774bc8_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_4519788fb9714023aab590eac8f09b69_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_6c34c101165440cfa9365d08c4f2069b_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "TSWBB-1",
    price: { saleprice: "375,000₫", realprice: "500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="100 cm">100 cm</option><option value="110 cm">110 cm</option><option value="120 cm">120 cm</option>',
  },
  {
    type: "the-sean-wolf-belts",
    soldout: null,
    title: {
      content: "THE SEAN WOLF BELT - GOLD",
      href: "products/the-sean-wolf-belt-gold",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_068e7eca6fe147fd8930e5891b8195ca_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_bcaf22699155405d89bbde4f515480f2_grande.jpg",
      href: "/products/the-sean-wolf-belt-gold",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_068e7eca6fe147fd8930e5891b8195ca_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_bcaf22699155405d89bbde4f515480f2_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_f5d363b7b2ce4328ac7897b987e586df_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_1db1abd538014506b30d9e81b8e39a08_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_d4127b69e5c24e708235dd897b8d5acd_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "TSWBG-1",
    price: { saleprice: "375,000₫", realprice: "500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "GOLD", color: "rgb(250, 235, 82) " },
    sizes:
      '<option value="100 cm">100 cm</option><option value="110 cm">110 cm</option><option value="120 cm">120 cm</option>',
  },
  {
    type: "the-sean-wolf-belts",
    soldout: null,
    title: {
      content: "THE SEAN WOLF BELT - SILVER",
      href: "products/the-sean-wolf-belt-silver",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_c8529adb121a4403bdc1e0a073c99f56_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_52e3608934cb430ab2617233c8427813_grande.jpg",
      href: "/products/the-sean-wolf-belt-silver",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_c8529adb121a4403bdc1e0a073c99f56_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_52e3608934cb430ab2617233c8427813_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_eacd2280e5674a078fcb5533501d2a27_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_449081bb50c4485bb5c6b6e7c8cce1dc_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_12c9204338224d68a4b201f785f1f139_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "TSWBS-1",
    price: { saleprice: "375,000₫", realprice: "500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "SILVER", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="100 cm">100 cm</option><option value="110 cm">110 cm</option><option value="120 cm">120 cm</option>',
  },
  {
    type: "sock",
    soldout: null,
    title: {
      content: "THEWOLF SOCK - BLACK",
      href: "products/thewolf-sock-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/2_fe86c276462343b5bc27df95b7badf8f_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/3_2dc2cdb4be4e4eb291ed975b68d6b6b0_grande.jpg",
      href: "/products/thewolf-sock-black",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/2_fe86c276462343b5bc27df95b7badf8f_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_2dc2cdb4be4e4eb291ed975b68d6b6b0_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_160c549ace0942ecaf8b8372aae0b55d_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_7258_e8007397f0ee4359aecc30f3019ce5b7_master.jpg",
        dataid: "#gal4",
      },
    ],
    code: "TWSOCKB",
    price: { saleprice: "75,000₫", realprice: "100,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes: '<option value="Free Size">Free Size</option>',
  },
  {
    type: "sock",
    soldout: null,
    title: {
      content: "THEWOLF SOCK - WHITE",
      href: "products/thewolf-sock-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/2_b02f763c79234296ad682b4750ece88c_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/3_8a4af7038b41435d8f63e3e0154a3e83_grande.jpg",
      href: "/products/thewolf-sock-white",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/2_b02f763c79234296ad682b4750ece88c_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_8a4af7038b41435d8f63e3e0154a3e83_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_9305d0fbf2a64a0ea17917fd63012219_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/img_7272_e27206b6b33f435aa44c50fd7cb3c5bf_master.jpg",
        dataid: "#gal4",
      },
    ],
    code: "TWSOCKW",
    price: { saleprice: "75,000₫", realprice: "100,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes: '<option value="Free Size">Free Size</option>',
  },
  {
    type: "the-amano-totebag",
    soldout: null,
    title: {
      content: "THEWOLF ALIN SAC BEIGE TOTE BAG",
      href: "products/thewolf-alin-sac-beige-tote-bag",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_2ca835fab27642ee9732062155749caa_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/5_de818c911926419191ce6e6931503a97_grande.jpg",
      href: "/products/thewolf-alin-sac-beige-tote-bag",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 440,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_2ca835fab27642ee9732062155749caa_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_de818c911926419191ce6e6931503a97_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_114135f3689e46b3b12f60c417e4a46b_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_50cd55ff85a64269bfc2b866cdd3b3ad_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_a78243c4f8864532951e35daa2837366_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_689629ec562d4d0ebc3e67f95c445116_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_be2e5007685c4fe8bdd56c09e4d27fca_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_37d9fb02f9f64ca3ba76efd82191c0a7_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_d189d3091c0e4373ad4f5fa1e8925699_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_7f8c649907714cc5b59294b6a78ee048_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "AMNBTTBBE",
    price: { saleprice: "1,320,000₫", realprice: "2,200,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BEIGE", color: "rgb(240, 188, 135)" },
  },
  {
    type: "the-amano-totebag",
    soldout: null,
    title: {
      content: "THEWOLF ALIN SAC PLAT TOTE BAG",
      href: "products/thewolf-alin-sac-plat-tote-bag",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_d494216cf31e434e95bd17f52011d097_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/5_29219f84863e44aa9721ab851d034d52_grande.jpg",
      href: "/products/thewolf-alin-sac-plat-tote-bag",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 440,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_d494216cf31e434e95bd17f52011d097_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_29219f84863e44aa9721ab851d034d52_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_8497ed3d61a94f22a03e66b7ab5b068c_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_e0b9996c762843288d1a74eb02d938d3_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_bfb66cf47dd3437bbcde5eaff8ba9a20_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_4770926bb2de4611945266b70c5cf910_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_f73e65df5dee48988972c55430a59c97_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_ac773d6e741f43a8b3e0ecd30a202aa1_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_ccc2e5848855496c8a6951495c8d9471_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_af4f8a50ecdb45ecbd289393728f72ea_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "AMNBTTBBL",
    price: { saleprice: "1,320,000₫", realprice: "2,200,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
  },
  {
    type: "the-amano-totebag",
    soldout: null,
    title: {
      content: "THEWOLF RESSY PETIT BEIGE",
      href: "products/thewolf-ressy-petit-beige",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_167c8d10e58642798bce77252ced9d17_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/4_2c648e793a61446c94d1fb49aca1828e_grande.jpg",
      href: "/products/thewolf-ressy-petit-beige",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_167c8d10e58642798bce77252ced9d17_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_2c648e793a61446c94d1fb49aca1828e_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_c33a802aaa484f6ea7b6bcb66afe27de_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_0f0fa22d2fa24fda8a31cb1029828e50_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_7cc156805f124034b2788a6fa37268e0_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_842c69b363544a469acfbf6cf3f0a603_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_2ddfb3244d43442d86c4645adc90625f_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_73d1aa15fadc420c8328c0c575fcb802_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_6ffec5fc48334f42addf8994647fad57_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "AMNSTTBBE",
    price: { saleprice: "960,000₫", realprice: "1,600,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BEIGE", color: "rgb(240, 188, 135)" },
  },
  {
    type: "the-amano-totebag",
    soldout: null,
    title: {
      content: "THEWOLF CROSSIC PETIT PLAT",
      href: "products/thewolf-crossic-petit-plat",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_8e51d542a2374c14aa5a1ce0a8659a0a_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/4_6d5e4c47e0a14f86bf98eba14ba15293_grande.jpg",
      href: "/products/thewolf-crossic-petit-plat",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_8e51d542a2374c14aa5a1ce0a8659a0a_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_6d5e4c47e0a14f86bf98eba14ba15293_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_d275459531c944a9966e72402bc8cd11_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_4fc5634b35ec4b15a21e405706b4aee6_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ccc2267c816c49528f4adb89c3579356_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_e168538766054cba90bb2fe62faa6de0_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_71f9003e0ca1412797d8b5fd9535cc59_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_cbe05a24bfa64765b3666da154c499c8_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_3434821ce7ad418985b1621b287aeabb_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "AMNSTTBBL",
    price: { saleprice: "960,000₫", realprice: "1,600,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
  },
  {
    type: "the-s-wolf-tote-bag-collection",
    soldout: "Hết hàng",
    title: {
      content: "THE S-WOLF TOTE BAG - BLACK",
      href: "products/the-s-wolf-tote-bag-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_33cee81212994464a8bb12fbf2847295_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_08d067a0d9434141b7085778d5b3d68f_grande.jpg",
      href: "/products/the-s-wolf-tote-bag-black",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_33cee81212994464a8bb12fbf2847295_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_08d067a0d9434141b7085778d5b3d68f_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_6ea9a6bd50454fa2ac3c78906afc39ff_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_93801af3a5a44cd5a15cd6ecaa47f74c_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_91dd8bb909c147588a5bd8538758766a_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "SWTTBBL",
    price: { saleprice: "510,000₫", realprice: "850,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { color: null },
  },
  {
    type: "the-s-wolf-tote-bag-collection",
    soldout: "Hết hàng",
    title: {
      content: "THE S-WOLF TOTE BAG - MILITARY GREEN",
      href: "products/the-s-wolf-tote-bag-military-green",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_473561fcf75e415a9b485be2bcc25130_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_ddd5847081cd44e28e30c51db6f27485_grande.jpg",
      href: "/products/the-s-wolf-tote-bag-military-green",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_473561fcf75e415a9b485be2bcc25130_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_ddd5847081cd44e28e30c51db6f27485_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_bb4456b669ce478cba60d82cba67a72f_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_5b5d6186f0d949648d8c6613926c3414_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_6f131937cf434027aa9e3588e74193df_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "SWTTBMG",
    price: { saleprice: "510,000₫", realprice: "850,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { color: null },
  },
  {
    type: "the-s-wolf-tote-bag-collection",
    soldout: null,
    title: {
      content: "THE S-WOLF TOTE BAG - ORANGE",
      href: "products/the-s-wolf-tote-bag-orange",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_80075d8a465d4a77952552158b058650_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_5cafb7a79436485ab97ce110f4acd722_grande.jpg",
      href: "/products/the-s-wolf-tote-bag-orange",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_80075d8a465d4a77952552158b058650_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_5cafb7a79436485ab97ce110f4acd722_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_496f3817958341949f1003ef6bab570b_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_5908e240e304446f979d104977ba71a7_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_e057778e1c934de98a46bcbedba2d396_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "SWTTBORA",
    price: { saleprice: "510,000₫", realprice: "850,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { title: "ORANGE", color: "rgb(231, 150, 17) " },
  },
  {
    type: "the-s-wolf-tote-bag-collection",
    soldout: "Hết hàng",
    title: {
      content: "THE S-WOLF TOTE BAG - WHITE CREAM",
      href: "products/the-s-wolf-tote-bag-white-cream",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_0e2271c9db5b45beba0c287a7f7cc0fa_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_c9d84e6bc4ee47309e58dce299c982d0_grande.jpg",
      href: "/products/the-s-wolf-tote-bag-white-cream",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_0e2271c9db5b45beba0c287a7f7cc0fa_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_c9d84e6bc4ee47309e58dce299c982d0_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_5035d8a780444bf588e9d0d510021567_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_677db50c1dd143dcbe53521f8befb9e8_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_255e0673d8b74312a9e3859e5aeebe50_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "SWTTBWC",
    price: { saleprice: "510,000₫", realprice: "850,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-40%",
    colors: { color: null },
  },
  {
    type: "the-wolf-jacket",
    soldout: null,
    title: {
      content: "THEWOLF CLASSIC JACKET - BLACK",
      href: "products/thewolf-classic-jacket-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_c8049ff34c4b4378b4266305e248f2e7_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_1f9d2c48300646478015518d7512a92c_grande.jpg",
      href: "/products/thewolf-classic-jacket-black",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_c8049ff34c4b4378b4266305e248f2e7_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_1f9d2c48300646478015518d7512a92c_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/jsl_0142__1__65697d69bac74f8ca41b6b5dfa221e1b_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/jsl_0164__1__3abb1d27d36f4391aa1893418f8a7534_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_1e08c344cd0e48cb9cbad14d068aebc9_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_da5a3567416e4cf8b78d0ba1fceb3f7a_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_b27df5dd97c248c29b5107bb2ce0ca8b_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_de8edb58fc6b48458a7546ed219a568b_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/jsl_0252__1__302d554579de4f07bcaccb8707340b7a_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/jsl_0162__1__2ebd1c8e6bff41e498e8fa2408bcc9b9_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "TWCJKB-1",
    price: { saleprice: "750,000₫", realprice: "1,500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-50%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="S">S</option><option value="M">M</option><option value="L">L</option>',
  },
  {
    type: "the-wolf-jacket",
    soldout: null,
    title: {
      content: "THEWOLF VARSITY JACKET - RED",
      href: "products/thewolf-varsity-jacket-red",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_fd0ced44df9449c693c38540ebd224f0_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_6f04ce7f452148fcb90200fdaa6dd583_grande.jpg",
      href: "/products/thewolf-varsity-jacket-red",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_fd0ced44df9449c693c38540ebd224f0_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_6f04ce7f452148fcb90200fdaa6dd583_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/jsl_0118__1__b4caf16b79804ca9b03bebbef02678a2_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/jsl_0096__1__629399dffbf64612ba51e3bc3bc7e2bd_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_9f6d889cd00a404aa4c6e4c3eed1f1cb_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_04ae4d66a1a64a0880145488a245ba99_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_1afd24746f4d4ccf825e640b08c4e4ed_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/jsl_0106__1__e671f41410824ee9acf81b99963e6045_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/jsl_0034__1__eed286a8481a476aa127499d910462f4_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TWVSJKR-1",
    price: { saleprice: "750,000₫", realprice: "1,500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-50%",
    colors: { title: "RED", color: "rgb(143, 56, 32) n" },
    sizes:
      '<option value="S">S</option><option value="M">M</option><option value="L">L</option><option value="XL">XL</option>',
  },
  {
    type: "the-sean-wolf-collection",
    soldout: null,
    title: {
      content: "THE SEAN WOLF CHUNKY LOAFER - BLACK OFF WHITE",
      href: "products/the-sean-wolf-chunky-loafer-black-off-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1.2_a6c975d1922945588b5baa534f41cf1d_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2.2_0ed45c816edd4b3baa11e8a86276cdba_grande.jpg",
      href: "/products/the-sean-wolf-chunky-loafer-black-off-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 667,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1.2_a6c975d1922945588b5baa534f41cf1d_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2.2_0ed45c816edd4b3baa11e8a86276cdba_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_407d957928384a2aa8860df2c91f5a96_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_7f2cb4e28b224fd6a2d5886aaccc1bd1_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_5cb1e6b563e94f628defb23e4a8d92fa_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/ban_sao_cua_7_4c3b5a2f12d54fd59ea4e73a029933d2_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ae02373b309249d2b83b094cc473b47c_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWCKLFBW-1",
    price: { saleprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "the-sean-wolf-collection",
    soldout: null,
    title: {
      content: "THE SEAN WOLF CHUNKY LOAFER - BLACK",
      href: "products/the-sean-wolf-chunky-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1.2_5e17d7249a4c484b80def698ad58f653_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2.2_d306dca79a6542bfa1b4a95a957e44ca_grande.jpg",
      href: "/products/the-sean-wolf-chunky-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 667,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1.2_5e17d7249a4c484b80def698ad58f653_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2.2_d306dca79a6542bfa1b4a95a957e44ca_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_906939852bd24067b20e724a72cdc3da_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_a600c62632484448954f6fc6e2f7d925_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_963e9a5f9aa94b91ba71349bbf8f087a_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/ban_sao_cua_7_1f3bf80d7bf94c248cad5201a5cef1a5_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_3acd7eabc3f849708be8b91f25dc436a_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWCKLFB-1",
    price: { saleprice: "2,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="34 EU">34 EU</option><option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 RU">43 RU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "the-sean-wolf-collection",
    soldout: null,
    title: {
      content: "THE SEAN WOLF MODERN LOAFER - BLACK OFF WHITE",
      href: "products/the-sean-wolf-modern-loafer-black-off-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_bf7eb1ac2c3d4ac9a5536afbcb8d38f3_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_2f6eb0e813cc48f297902c3b4facd716_grande.jpg",
      href: "/products/the-sean-wolf-modern-loafer-black-off-white",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_bf7eb1ac2c3d4ac9a5536afbcb8d38f3_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_2f6eb0e813cc48f297902c3b4facd716_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_124d26da37e5455395f5dc1973353416_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_c53209eb2f374de9bdec2423d71f0f32_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_fdccb6536aa74cb69b5e4503f07c3cdb_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_4c176d8cd21d47d99610c079ef9ffb4c_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ed14433bad5142f692ce4d95361d19b6_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWMDLFBW-1",
    price: { saleprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "the-sean-wolf-collection",
    soldout: null,
    title: {
      content: "THE SEAN WOLF MODERN LOAFER - BLACK",
      href: "products/the-sean-wolf-modern-loafer-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_3c8dae73de314ca6bb817dd6501729eb_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_27cc1c6a50844ee393eb4f04b6a9ad77_grande.jpg",
      href: "/products/the-sean-wolf-modern-loafer-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_3c8dae73de314ca6bb817dd6501729eb_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_27cc1c6a50844ee393eb4f04b6a9ad77_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_3d12cdbebadd4eeabedc07c9bde97898_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_9edecc7b005440fa893e0b5aab2a56d0_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_a55d12e26c5d4183832f85a7d8f07114_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_20e6d095848945d58e2b13dc13cb677e_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_3d777fa3d3ed4fbc8a261d2b9ad304c6_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TSWMDLFB-1",
    price: { saleprice: "1,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option><option value="45 EU">45 EU</option>',
  },
  {
    type: "the-sean-wolf-collection",
    soldout: null,
    title: {
      content: "THE SEAN WOLF SLIDE SANDAL - BROWN",
      href: "products/the-sean-wolf-slide-sandal-brown",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_ee08296bc9c9459a80e1b19e41c7a980_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_dc3a58ca171249b1be863444cdcfc62c_grande.jpg",
      href: "/products/the-sean-wolf-slide-sandal-brown",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_ee08296bc9c9459a80e1b19e41c7a980_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_dc3a58ca171249b1be863444cdcfc62c_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_523f3408fd404762a30e7b4ef4982ffb_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_a11c4def9d7b4fb5a3246f48e1dfdf84_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_067d70808f3a48678a280eb0771b5017_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_5e599c71d8f54ddc9c033ac65f5964b8_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/21_040784196b2140c3bef22662d47276d0_master.jpeg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/24_896d89085ecc4870ba69de4999421e7a_master.jpeg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/25_8532221019b2411d9a2c53e507de4edf_master.jpeg",
        dataid: "#gal9",
      },
    ],
    code: "TSWSLSDB-1",
    price: { saleprice: "675,000₫", realprice: "900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BROWN", color: "rgb(110, 74, 74) n" },
    sizes:
      '<option value="36">36</option><option value="37">37</option><option value="38">38</option><option value="39">39</option><option value="40">40</option><option value="41">41</option><option value="42">42</option><option value="44">44</option>',
  },
  {
    type: "the-sean-wolf-collection",
    soldout: null,
    title: {
      content: "THE SEAN WOLF SLIDE SANDAL - WHITE",
      href: "products/the-sean-wolf-slide-sandal-white",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_c98888e8f84a481587ddbc86644c87a8_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_a34e9084a3fd425787284e451b285afc_grande.jpg",
      href: "/products/the-sean-wolf-slide-sandal-white",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_c98888e8f84a481587ddbc86644c87a8_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_a34e9084a3fd425787284e451b285afc_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_4ced73539a4d4e538ea1fc06d6854eb3_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_a689d4c6f9964c95b13ca580cbd7535c_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_f9afaa13110d4ccbb6416651ce501e4e_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_60f764ce975b4981b5072da431f5c38d_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_0dc0c151b3ec42c8b2d40028f984ec67_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/31_4061865a803b4702a2bad50f841c31df_master.jpeg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/33_170fffc4b1c34facb03801c137d1cbfd_master.jpeg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/35_d00f35ec29024cda8092ea0a41b37fdb_master.jpeg",
        dataid: "#gal10",
      },
    ],
    code: "TSWSLSDW-1",
    price: { saleprice: "675,000₫", realprice: "900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "WHITE", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="36">36</option><option value="37">37</option><option value="38">38</option><option value="39">39</option><option value="40">40</option><option value="41">41</option><option value="42">42</option><option value="43">43</option><option value="44">44</option>',
  },
  {
    type: "the-sean-wolf-collection",
    soldout: null,
    title: {
      content: "THE SEAN WOLF SLIDE SANDAL - BLACK",
      href: "products/the-sean-wolf-slide-sandal-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_313031701a7b4c05af0b5f53fbbf52c8_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_077ecba257b24bc7a5a0f6062fdee184_grande.jpg",
      href: "/products/the-sean-wolf-slide-sandal-black",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_313031701a7b4c05af0b5f53fbbf52c8_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_077ecba257b24bc7a5a0f6062fdee184_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_ebcf5b269cb747d395efe1cf9b8af161_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_9c50122d185845909808bf31b2bf1d67_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_3d43b5ca762b4f69a0fea44aae17cf9d_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_020150bd6c684cf1a7e175f7de49534c_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_866c1429c3214e68877648d1a3aa2444_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/26_1909ca1005c9468689e8825a37dd7593_master.jpeg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/28_3ed72e740a684cd1b943a21ff9bc069b_master.jpeg",
        dataid: "#gal9",
      },
    ],
    code: "TSWSLSDB-1",
    price: { saleprice: "675,000₫", realprice: "900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="36">36</option><option value="37">37</option><option value="38">38</option><option value="39">39</option><option value="40">40</option><option value="41">41</option><option value="42">42</option><option value="43">43</option><option value="44">44</option>',
  },
  {
    type: "the-sean-wolf-collection",
    soldout: null,
    title: {
      content: "THE SEAN WOLF BELT - BLACK",
      href: "products/the-sean-wolf-belt-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_ba5398799ded4c4f9966902f635330b4_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_be90b9ee66134cffbef6e124738825c3_grande.jpg",
      href: "/products/the-sean-wolf-belt-black",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_ba5398799ded4c4f9966902f635330b4_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_be90b9ee66134cffbef6e124738825c3_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_2fe4ae129b8f484d8a0ab9b41e774bc8_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_4519788fb9714023aab590eac8f09b69_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_6c34c101165440cfa9365d08c4f2069b_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "TSWBB-1",
    price: { saleprice: "375,000₫", realprice: "500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="100 cm">100 cm</option><option value="110 cm">110 cm</option><option value="120 cm">120 cm</option>',
  },
  {
    type: "the-sean-wolf-collection",
    soldout: null,
    title: {
      content: "THE SEAN WOLF BELT - GOLD",
      href: "products/the-sean-wolf-belt-gold",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_068e7eca6fe147fd8930e5891b8195ca_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_bcaf22699155405d89bbde4f515480f2_grande.jpg",
      href: "/products/the-sean-wolf-belt-gold",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_068e7eca6fe147fd8930e5891b8195ca_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_bcaf22699155405d89bbde4f515480f2_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_f5d363b7b2ce4328ac7897b987e586df_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_1db1abd538014506b30d9e81b8e39a08_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_d4127b69e5c24e708235dd897b8d5acd_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "TSWBG-1",
    price: { saleprice: "375,000₫", realprice: "500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "GOLD", color: "rgb(250, 235, 82) " },
    sizes:
      '<option value="100 cm">100 cm</option><option value="110 cm">110 cm</option><option value="120 cm">120 cm</option>',
  },
  {
    type: "the-sean-wolf-collection",
    soldout: null,
    title: {
      content: "THE SEAN WOLF BELT - SILVER",
      href: "products/the-sean-wolf-belt-silver",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_c8529adb121a4403bdc1e0a073c99f56_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_52e3608934cb430ab2617233c8427813_grande.jpg",
      href: "/products/the-sean-wolf-belt-silver",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_c8529adb121a4403bdc1e0a073c99f56_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_52e3608934cb430ab2617233c8427813_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_eacd2280e5674a078fcb5533501d2a27_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_449081bb50c4485bb5c6b6e7c8cce1dc_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_12c9204338224d68a4b201f785f1f139_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "TSWBS-1",
    price: { saleprice: "375,000₫", realprice: "500,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "SILVER", color: "rgba(0, 0, 0, 0) n" },
    sizes:
      '<option value="100 cm">100 cm</option><option value="110 cm">110 cm</option><option value="120 cm">120 cm</option>',
  },
  {
    type: "the-sean-wolf-collection",
    soldout: null,
    title: {
      content: "THEWOLF SIGNATURE SHOE HORN",
      href: "products/thewolf-signature-shoe-horn",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_48e902dfc1b449b8bba294752031c219_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_da075d9e16c04f3484bd900ed1aa1472_grande.jpg",
      href: "/products/thewolf-signature-shoe-horn",
    },
    buyinstallment: null,
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_48e902dfc1b449b8bba294752031c219_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_da075d9e16c04f3484bd900ed1aa1472_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_78e7d55c74774998a73e97b4cb94a0d9_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_6ec84bf1d35943afbc0559fca8f417e8_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_284db9d8fc4445b58187cf8046d5197f_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "TWSSH",
    price: { saleprice: "187,500₫", realprice: "250,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "GOLD", color: "rgb(250, 235, 82) " },
  },
  {
    type: "the-mars-wolf-collection",
    soldout: null,
    title: {
      content: "THE MARS WOLF HIGH COMBAT BOOT - BLACK",
      href: "products/the-mars-wolf-high-combat-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_83cd49d9ea2444a695f3c20cef4e1efb_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_b1e11d2d71c84773b32dcd3623e5eb31_grande.jpg",
      href: "/products/the-mars-wolf-high-combat-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 725,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_83cd49d9ea2444a695f3c20cef4e1efb_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_b1e11d2d71c84773b32dcd3623e5eb31_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_d6061286f975426fbaed49f0f1f3c205_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_49918cb1ddbe4ea6b10d0ade71db9e16_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_e58b79c3080f46b4bf20496127edeb60_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_e41a0977ff3841c2a7c84e14e5eba0b4_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/8_9bf6f15f53824487ae6f20c06ebf50d1_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_c9b1de1fbd5f4719b9ef588377c13ee9_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-07_10aad4ea40044e31aaca3817207735c0_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/twolf-127__2__fba130632ad4455786ca1c533ef0a86d_master.jpg",
        dataid: "#gal10",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/twolf-174__1__f37075361b124934bbc349eba8031853_master.jpg",
        dataid: "#gal11",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/twolf-192__1__9cad47af967c46659dccb1b00360f19f_master.jpg",
        dataid: "#gal12",
      },
    ],
    code: "TMWHCBBB-1",
    price: { saleprice: "2,175,000₫", realprice: "2,900,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "the-mars-wolf-collection",
    soldout: null,
    title: {
      content: "THE MARS WOLF MID COMBAT BOOT - BLACK",
      href: "products/the-mars-wolf-mid-combat-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_af092ede6d6540f5b71887a29cdb43f6_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_a1bfc7711964431cbabd65099e2162c0_grande.jpg",
      href: "/products/the-mars-wolf-mid-combat-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 700,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_af092ede6d6540f5b71887a29cdb43f6_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_a1bfc7711964431cbabd65099e2162c0_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_a0affc88be774d89b58da1818530c181_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_7925955322ac4aec9c96f44b98c373dc_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_ec7ce37e1288467e8e0591a5403049ef_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_c532ab746af84585ae4df547171c2c37_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/layout_detail-08_441752c7522247a78b0438005da3aaf8_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1__2__806a0d422efc4cd9b574faf38e3c4c95_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_69da20e4195b4f48837832218d65265a_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_fc5115a7b651477d8583dbe7acb1f396_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "TMWMCBBB-1",
    price: { saleprice: "2,100,000₫", realprice: "2,800,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "the-mars-wolf-collection",
    soldout: null,
    title: {
      content: "THE MARS WOLF CHELSEA BOOT - BLACK",
      href: "products/the-mars-wolf-chelsea-boot-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_071068beb0904e37b19b11b9408d102c_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_1708360bf5594073874dc576ea574d17_grande.jpg",
      href: "/products/the-mars-wolf-chelsea-boot-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_071068beb0904e37b19b11b9408d102c_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_1708360bf5594073874dc576ea574d17_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_93756c7e8c3242cc9a0452810ae05916_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_f856cdb966414f27a581ba04c0b7ad50_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_a8e7728f785e428987adb35da7ae52e6_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_abacfc87caf440e4bc3596b67bc8fe2b_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/8_d9d6f2f8923d405a9d71cccb65e5e7d8_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TMWCSBB-1",
    price: { saleprice: "1,800,000₫", realprice: "2,400,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "the-mars-wolf-collection",
    soldout: null,
    title: {
      content: "THE MARS WOLF CHELSEA BOOT SPECIAL EDITION - BLACK",
      href: "products/the-mars-wolf-chelsea-boot-special-edition-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_b4e517f8116943afbb687158c880a419_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_12566a1ceef44398a539d427762873fe_grande.jpg",
      href: "/products/the-mars-wolf-chelsea-boot-special-edition-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 867,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_b4e517f8116943afbb687158c880a419_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_12566a1ceef44398a539d427762873fe_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_ce52721e5f9c4cd3aef21926f52984de_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_0d5bff0c2d1f4fc182ac75ad4e11e762_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_22395eba4fcb471aa63027577b75284e_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_6c183547adae47a2958ef76e0fa08a30_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_878267d34345452d8383d029f04e5bab_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2__2__1f747a7ec74e4196b914567f26a99dfe_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_76dfef8a8c634527998715ff38782c23_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_c154483cba9442c6a41fb37be61f0ded_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "TMWCSBSEB-1",
    price: { saleprice: "2,600,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "the-mars-wolf-collection",
    soldout: null,
    title: {
      content: "THE MARS WOLF CHELSEA BOOT - BLACK SUEDE",
      href: "products/the-mars-wolf-chelsea-boot-black-suede",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_5854fa0ea7fa4b709f6a1f154c1ece8b_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_7293d39bc55d4a4aa7958d4643ed3ea9_grande.jpg",
      href: "/products/the-mars-wolf-chelsea-boot-black-suede",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_5854fa0ea7fa4b709f6a1f154c1ece8b_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_7293d39bc55d4a4aa7958d4643ed3ea9_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_dfb2b36547744d1fa57600d2707323f7_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_0baa5164d5b648ce93e78b0386b80a4f_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_0922a0e01a6d4b4e888d3ff0bf690660_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_38808b5416504f0a80be408603af2298_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_bfdf87d1e4da420bbf8f8bb7afea0725_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "TMWCSBBS-1",
    price: { saleprice: "1,800,000₫", realprice: "2,400,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "the-mars-wolf-collection",
    soldout: null,
    title: {
      content: "THE MARS WOLF CHELSEA BOOT - TAN SUEDE",
      href: "products/the-mars-wolf-chelsea-boot-tan-suede",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_37683ec15b594dd79467d3a367f77090_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_965bb3f9d0a44d7a8a9536f653adadef_grande.jpg",
      href: "/products/the-mars-wolf-chelsea-boot-tan-suede",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 600,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_37683ec15b594dd79467d3a367f77090_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_965bb3f9d0a44d7a8a9536f653adadef_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_a1433182195646d596e8266eff76db82_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_bcc5b859d43b4e3e85bcee38b1702b5d_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_d87cc6f2df6140ceb5a56b741026664f_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_a8ce5fafa0444fbd84f2c4717114484f_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_58a59ea85a55495a9bae4aee7dfd3058_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_87a4afcb012e4e409bf8c2ab082414c4_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_7718bdb1e89c4348bb9b37276e9b939d_master.jpg",
        dataid: "#gal9",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_a389c3e7af6b474296712ae2b946d3ed_master.jpg",
        dataid: "#gal10",
      },
    ],
    code: "TMWCSBTS-1",
    price: { saleprice: "1,800,000₫", realprice: "2,400,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "TAN", color: "rgb(225, 179, 130)" },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "the-mars-wolf-collection",
    soldout: null,
    title: {
      content: "THE MARS WOLF DERBY - BLACK",
      href: "products/the-mars-wolf-derby-black",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/1_25ccd02310964c94bb28967445424f9c_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_1e24b3e81ef7405a95192a41122473a2_grande.jpg",
      href: "/products/the-mars-wolf-derby-black",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 613,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/1_25ccd02310964c94bb28967445424f9c_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_1e24b3e81ef7405a95192a41122473a2_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_f3e31d736c7e405d9c59b5d74cc00c75_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_0695d0c5948a4fd19835d792d6af9473_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_7423bf38f80c424da13ca0dc689f5ed4_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_ca569ae49ae54adc911f36e10f0d50e3_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_4b5bfc0b304c4a48b39ec611ee67d917_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_373dcdc9ffe34271bd5c56085925a0e6_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_af1ec0e1d8de4a1692b8e747a32dde64_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "TMWDBB-1",
    price: { saleprice: "1,837,500₫", realprice: "2,450,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    discount: "-25%",
    colors: { title: "BLACK", color: "rgb(0, 0, 0) none " },
    sizes:
      '<option value="35 EU">35 EU</option><option value="36 EU">36 EU</option><option value="37 EU">37 EU</option><option value="38 EU">38 EU</option><option value="39 EU">39 EU</option><option value="40 EU">40 EU</option><option value="41 EU">41 EU</option><option value="42 EU">42 EU</option><option value="43 EU">43 EU</option><option value="44 EU">44 EU</option>',
  },
  {
    type: "shoes-for-decor",
    soldout: "Hết hàng",
    title: {
      content: "MỸ DIỆU CHUNKY LOAFER - SHOE FOR DECOR",
      href: "products/my-dieu-chunky-loafer-shoe-for-decor",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/my_dieu_35fa2db1596641c6ba74bbff2bd5d67a_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/1_87541675a08643689ed07680eef7e7b5_grande.jpg",
      href: "/products/my-dieu-chunky-loafer-shoe-for-decor",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 1,667,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/my_dieu_35fa2db1596641c6ba74bbff2bd5d67a_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_87541675a08643689ed07680eef7e7b5_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_fb980440ff3e407fbae602da3eabcee0_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/33_45720ad142784904994714549232ab96_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_9129bc890b114fcfa84db0220e202783_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_f191a9bdfc9e4a48ae87d53ac5d96718_master.jpg",
        dataid: "#gal6",
      },
    ],
    code: "MDCKLF",
    price: { saleprice: "5,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { color: null },
  },
  {
    type: "shoes-for-decor",
    soldout: "Hết hàng",
    title: {
      content: "DORAEMON DERBY - SHOE FOR DECOR",
      href: "products/doraemon-derby-shoe-for-decor",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/doraemon_92cb8622d56d4ca6b5a075ec884bc851_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/1_87de7355a2df4c409ba1a49217d33d79_grande.jpg",
      href: "/products/doraemon-derby-shoe-for-decor",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 1,667,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/doraemon_92cb8622d56d4ca6b5a075ec884bc851_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_87de7355a2df4c409ba1a49217d33d79_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_9f6ed3d491494e379a941085dbebb586_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_bbeeff424a9a47d9a0303ae876de197d_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_7be6831d751447808a9c632949291c8a_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_c2b7386361224c6eb54cdd07011b5556_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_df4410c71c8449c2b6f618eb0035a931_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_59c753a5045f49998edb456f932e97aa_master.jpg",
        dataid: "#gal8",
      },
    ],
    code: "DRMDB",
    price: { saleprice: "5,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { color: null },
  },
  {
    type: "shoes-for-decor",
    soldout: null,
    title: {
      content: "MONKEY D. LUFFY PENNY LOAFER - SHOE FOR DECOR",
      href: "products/thewolf-monkey-d-luffy-penny-loafer",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/z3751830007919_8b0d2d47c95b5b0585e804e4de7defd6_5577e5182eca4c9aaaca7bd098a53b30_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/8_02c016c5a10040da8f4a307ce599c000_grande.jpg",
      href: "/products/thewolf-monkey-d-luffy-penny-loafer",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 1,667,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/z3751830007919_8b0d2d47c95b5b0585e804e4de7defd6_5577e5182eca4c9aaaca7bd098a53b30_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/8_02c016c5a10040da8f4a307ce599c000_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_c30186291a33491b8928252bab644716_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_4d3d4d8767fa4a43933a32a8d4243e22_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_d79ab9c21aec4c33b915ce3acb1008f6_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_fca907d3c6534c9ab0f104f87c57a61d_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_d2bdb3a4063541488f71adfdc9ffd8fb_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_dd8d011ac72644ffbadedf7e49c112b6_master.jpg",
        dataid: "#gal8",
      },
    ],
    code: "MKDLFPNL",
    price: { saleprice: "5,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { color: null },
  },
  {
    type: "shoes-for-decor",
    soldout: null,
    title: {
      content: "DR STRANGE CHUNKY HI-ANKLE COMBAT BOOTS - SHOES FOR DECOR",
      href: "products/dr-strange-chunky-hi-ankle-combat-boots",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/thewolf_dr_strange_chunky_hi-ankle_combat_boots_5_a25a4465b5a545e1b0dbcd0731f5566d_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/thewolf_dr_strange_chunky_hi-ankle_combat_boots_2_ad79d605c2634c4d95062c523607c55a_grande.jpg",
      href: "/products/dr-strange-chunky-hi-ankle-combat-boots",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 1,667,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_dr_strange_chunky_hi-ankle_combat_boots_5_a25a4465b5a545e1b0dbcd0731f5566d_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_dr_strange_chunky_hi-ankle_combat_boots_2_ad79d605c2634c4d95062c523607c55a_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_dr_strange_chunky_hi-ankle_combat_boots_1_9cd9d258270046f99a0baae184296566_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_dr_strange_chunky_hi-ankle_combat_boots_3_7e3c02bcf83c44749e1d1cfd19864f5b_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_dr_strange_chunky_hi-ankle_combat_boots_4_d9beaffa9e434a7799bfe8350b5ac4e0_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_dr_strange_chunky_hi-ankle_combat_boots_d41b4408a4674d86abe1b0ced2b6a42e_master.jpg",
        dataid: "#gal6",
      },
    ],
    code: "DRSCKHACB",
    price: { saleprice: "5,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { title: "Đen Xanh Navy", color: "rgb(0, 38, 111) no" },
  },
  {
    type: "shoes-for-decor",
    soldout: "Hết hàng",
    title: {
      content: "THE GODZILLA CHUNKY COMBAT BOOTS - SHOES FOR DECOR",
      href: "products/the-godzilla-chunky-combat-boots",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/z3440973890195_30c1de928dc2195af396fde44b799d15_53d5abb929904572b1281a63b37a1060_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/thewolf_godzilla_chunky_combat_boots_4_642bbc87337c4cd7a0ff71da26c4c4e2_grande.jpg",
      href: "/products/the-godzilla-chunky-combat-boots",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 2,334,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/z3440973890195_30c1de928dc2195af396fde44b799d15_53d5abb929904572b1281a63b37a1060_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_godzilla_chunky_combat_boots_4_642bbc87337c4cd7a0ff71da26c4c4e2_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_godzilla_chunky_combat_boots_3_e3d44c6110ca4f0aaa8065f55b6b5028_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_godzilla_chunky_combat_boots_5_c20e18a0d2d2451dab3e27a0bf9725d5_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/thewolf_godzilla_chunky_combat_boots_2_858e2a77d90a4344be12f0e893be6678_master.jpg",
        dataid: "#gal5",
      },
    ],
    code: "GZLCKCB",
    price: { saleprice: "7,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { color: null },
  },
  {
    type: "shoes-for-decor",
    soldout: "Hết hàng",
    title: {
      content: "THE MONEY HEIST LOAFER – SHOES FOR DECOR",
      href: "products/the-money-heist-loafer-shoes-for-decor",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/2_bcc2811fc1a24dd897eece3383eece61_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/5_7e4c9056bd9140718a5c7f3ada458744_grande.jpg",
      href: "/products/the-money-heist-loafer-shoes-for-decor",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 1,334,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/2_bcc2811fc1a24dd897eece3383eece61_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_7e4c9056bd9140718a5c7f3ada458744_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/6_a2ae272119a54772a02a5f3a0a4663ae_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/8_63369ab42ddd475c81ddf7b29104b4e4_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_a5ede31673004f07a6fbb9d7c139d4ea_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_2710e03436604322a8e8f6cbe3547fc0_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/3_6349b342a57a462392f7908bf8c5e003_master.jpg",
        dataid: "#gal7",
      },
    ],
    code: "MNHSFD",
    price: { saleprice: "4,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { color: null },
  },
  {
    type: "shoes-for-decor",
    soldout: "Hết hàng",
    title: {
      content: "THE SQUID GAME LOAFER – SHOES FOR DECOR",
      href: "products/the-squid-game-loafer-shoes-for-decor",
    },
    imgcard: {
      src1: "https://product.hstatic.net/200000033444/product/12_a3e57fabf0214267b5a36c2b4449bbc2_grande.jpg",
      src2: "https://product.hstatic.net/200000033444/product/2_983817019f3f453f849643a0a53e0d7c_grande.jpg",
      href: "/products/the-squid-game-loafer-shoes-for-decor",
    },
    buyinstallment: { app: "Fundiin", content: "hoặc 1,334,000₫ x 3 kỳ với" },
    imgs: [
      {
        src: "https://product.hstatic.net/200000033444/product/12_a3e57fabf0214267b5a36c2b4449bbc2_master.jpg",
        dataid: "#gal1",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/2_983817019f3f453f849643a0a53e0d7c_master.jpg",
        dataid: "#gal2",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/1_40def5855bdb47c5af227a16ff50c287_master.jpg",
        dataid: "#gal3",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/8_bbf4bad5facf4762be9d424af24a56c8_master.jpg",
        dataid: "#gal4",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/7_28e800fed9fd4086adeb06c1f903db26_master.jpg",
        dataid: "#gal5",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/9_2651b1bbca0e4a12818ba8f4feeb1bd1_master.jpg",
        dataid: "#gal6",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/5_d0da29b83add4c3bb46cf0ddfa364ec7_master.jpg",
        dataid: "#gal7",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/10_91344d6cc9f44e8dbb41b93b7bb13086_master.jpg",
        dataid: "#gal8",
      },
      {
        src: "https://product.hstatic.net/200000033444/product/4_a17457b6d30e45309450919f596edb9e_master.jpg",
        dataid: "#gal9",
      },
    ],
    code: "SQGSFD",
    price: { saleprice: "4,000,000₫" },
    guarantee:
      '\n          <ul>\n            <li><span style="font-size: 9pt;">Bảo hành trọn đời về các vấn đề bong tróc keo đế.</span></li>\n            <li><span style="font-size: 9pt;">Miễn phí 3 lần vệ sinh đánh bóng giày (mang theo thẻ vệ sinh).</span></li>\n            <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất:</span>\n            <ul style="list-style-type: circle;">\n              <li><span style="font-size: 9pt;">Đổi hàng trong vòng 1 tuần nếu xảy ra vấn đề do nhà sản xuất.</span></li>\n              <li><span style="font-size: 9pt;">Trong trường hợp chưa mang lần nào, nhưng bị vấn đề về đế.</span></li>\n            </ul>\n            <li><span style="font-size: 9pt;">Mua tại store hỗ trợ đổi hàng trong vòng 24h.</span></li>\n          </ul>',
    exchange:
      '\t\t\t\t \n          <ul>\n            <li><span style="font-size: 9pt;">Hàng phải còn nguyên vẹn không có bất kì sự thay đổi nào.</span></li>\n            <li><span style="font-size: 9pt;">Hàng khi đóng gói lại phải kèm túi hộp vẫn còn nguyên vẹn.</span></li>\n            <li><span style="font-size: 9pt;">Phải điền đầy đủ thông tin để tránh thất lạc.</span></li>\n            <li><span style="font-size: 9pt;">Mọi chi phí vận chuyển đổi size sẽ do bên mua chịu toàn bộ chi phí.</span></li>\n          </ul>\n          <div><span style="font-size: 9pt;">&nbsp;LƯU Ý: Nếu lỗi nhà cung cấp chúng tôi sẽ chịu hoàn toàn chi phí.</span></div>\n          <div><span style="font-size: 9pt;">&nbsp;* Liên hệ trực tiếp với chúng tôi để việc trao đổi trở nên dễ dàng hơn.</span></div>            \n        ',
    colors: { color: null },
    sizes: '<option value="40">40</option>',
  },
];

const shuffle = (arr) => {
  return [...arr].sort(() => Math.random() - 0.5);
};

const getProductsByType = (type) => {
  let resuls;
  if (type === "giay-nam-the-wolf") {
    resuls = products.filter(
      (el) =>
        el.type === "chelsea-boot-nam" ||
        el.type === "derby-nam" ||
        el.type === "giay-loafer-nam" ||
        el.type === "giay-slipper-nam" ||
        el.type === "harness-boot-nam" ||
        el.type === "combat-boot-nam" ||
        el.type === "oxford-nam" ||
        el.type === "thewolf-slide-sandal"
    );
  } else if (type === "giay-nu") {
    resuls = products.filter(
      (el) =>
        el.type === "chelsea-boot-nu" ||
        el.type === "combat-boot-nu" ||
        el.type === "derby-nu" ||
        el.type === "giay-loafer-nu" ||
        el.type === "giay-slipper-nu" ||
        el.type === "the-lady-wolf-slide-sandal"
    );
  } else if (type === "phu-kien") {
    resuls = products.filter(
      (el) => el.type === "the-sean-wolf-belts" || el.type === "sock"
    );
  } else if (type === "the-wolf-tote-bag") {
    resuls = products.filter(
      (el) =>
        el.type === "the-amano-totebag" ||
        el.type === "the-s-wolf-tote-bag-collection"
    );
  } else if (type === "clearance-sale-25") {
    resuls = products.filter((el) => el.discount === "-25%");
  } else if (type === "clearance-sale-30") {
    resuls = products.filter((el) => el.discount === "-30%");
  } else if (type === "clearance-sale-40") {
    resuls = products.filter((el) => el.discount === "-40%");
  } else if (type === "clearance-sale-50") {
    resuls = products.filter((el) => el.discount === "-50%");
  } else if (type === "clearance-sale-60") {
    resuls = products.filter((el) => el.discount === "-60%");
  } else if (type === "all") {
    resuls = products;
  } else {
    resuls = products.filter((el) => el.type === type);
  }
  return shuffle(resuls);
};

const getProductsByHref = (href) => {
  let resuls = products.filter((el) => el.imgcard.href === href);
  return resuls;
};

const getProductsById = (id) => {
  let result = products.find((el) => el.code === id);
  return result;
};

const getProductByKey = (key) => {
  //console.log(key)
  if (key === "") return [];
  else {
    let result = [];
    products.forEach((el) => {
      let title = el.title.content;
      //let arr = title.replace("-", "").split(" ");
      //console.log('THE MARS WOLF CHELSEA BOOT - BLACK'.search('BLACK'))
      //console.log(key.toUpperCase())
      if (title.search(key.toUpperCase()) !== -1) {
        //console.log(title);
        result.push(el);
      }
    });
    return shuffle(result);
  }
};

export {
  getProductsByType,
  getProductsByHref,
  getProductsById,
  getProductByKey,
};
